import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILabSlot, ISlot } from '@Reducers/schedule';
import { InvoiceMode } from '@Services/Billing/billing-payload';
import { IAddress } from '@Services/Patient/patient-payload';

export interface Selectables {
  slot?: ISlot;
  date?: string;
  confirmedBookingDate?: string;
  medium?: 'online' | 'offline' | 'instant';
  language?: string;
  locationCode?: string;
  city?: string;
  address?: IAddress;
  labCollectionDate?: string;
  labCollectionSlot?: ILabSlot;
  paymentMode?: InvoiceMode;
}

const initialState: Selectables = {
  slot: {} as ISlot,
  labCollectionSlot: {} as ILabSlot,
  paymentMode: InvoiceMode.Prepaid,
};

const selectablesSlice = createSlice({
  name: 'selectables',
  initialState,
  reducers: {
    setSlot: (state, action: PayloadAction<ISlot>) => {
      state.slot = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setConfirmedBookingDate: (state, action: PayloadAction<string>) => {
      state.confirmedBookingDate = action.payload;
    },
    setMedium: (state, action: PayloadAction<Selectables['medium']>) => {
      state.medium = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setLocationCode: (state, action: PayloadAction<string>) => {
      state.locationCode = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setAddress: (state, action: PayloadAction<IAddress>) => {
      state.address = action.payload;
    },
    setLabCollectionDate: (state, action: PayloadAction<string>) => {
      state.labCollectionDate = action.payload;
    },
    setLabCollectionSlot: (state, action: PayloadAction<ILabSlot>) => {
      state.labCollectionSlot = action.payload;
    },
    setPaymentMode: (state, action: PayloadAction<InvoiceMode>) => {
      state.paymentMode = action.payload;
    },
    clear: () => initialState,
  },
});

export const SelectablesActions = selectablesSlice.actions;
export default selectablesSlice.reducer;
