import { IResourceData } from '@Reducers/patient';

export const formatResources = (resources: any): IResourceData[] => {
  return resources?.map((resource): IResourceData => {
    return {
      id: resource?.id,
      title: resource?.title,
      description: resource?.description,
      imageUrl: resource?.imageUrl,
      externalUrl: resource?.externalUrl,
      type: resource?.type,
      badge: resource?.badge,
      label: resource?.label,
      category: resource?.category,
      subCategory: resource?.subCategory,
    };
  });
};
