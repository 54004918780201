import { AxiosResponse } from 'axios';

import { Axios } from '../AxiosApiServerWrapper';

export const RedcliffeService = {
  GetRedcliffeLocalities: async (payload: { query: string }): Promise<AxiosResponse> => {
    const url = `vendors/redcliffe/locality`;
    return Axios.Request('GET', url, {}, payload);
  },
  GetRedcliffeSlots: async (payload: {
    eloc: string;
    collectionDate: Date;
    slotPeriod: number;
  }): Promise<AxiosResponse> => {
    const url = `vendors/redcliffe/slots`;
    return Axios.Request('GET', url, {}, payload);
  },
  CreateRedcliffeFulfillment: async (payload): Promise<AxiosResponse> => {
    const url = `fulfillments/lab`;
    return Axios.Request('POST', url, payload, {});
  },
  GetLabPreparation: async (payload: { invoiceId: string }): Promise<AxiosResponse> => {
    const url = `catalog/labtest/preparation`;
    return Axios.Request('GET', url, {}, payload);
  },
};
