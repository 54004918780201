import { IResourceData } from '@Reducers/patient';

export const ERROR_MESSAGES = {
  generic: 'Oops something went wrong!',
  update: 'Unable to update data!',
  get: 'Unable to get data!',
  reschedule: 'Unable to reschedule!',
  call: 'Unable to initiate call!',
  location: 'Location is non-serviceable',
};

export const CONSULTATION_CODE_DEFAULT = 'SC';

export const CONSULTATION_NAME_DEFAULT = 'Screening Call';

export const ONLINE_LOCATION_ID = process.env.ONLINE_LOCATION_ID;

export const PRACTO_LOCATION_ID = process.env.PRACTO_LOCATION_ID;

export const embeddedRoutes = ['login', 'authorize'];

export const getConsultationDetails = (consultationCode: string, consultationName = '') => {
  switch (consultationCode) {
    case 'DUA6':
    case 'DUB6':
    case 'CSTP6':
    case 'LDTP6':
    case 'VTP6':
    case 'PATP6':
    case 'PETP6':
    case 'EDTP6':
      return {
        banner: 'TP',
        type: 'Allo Treatment Plan',
        suffix: 'purchase is complete',
        title: consultationName,
        subOne: {
          icon: 'medic',
          text: 'Evidence based solutions',
        },
        subTwo: {
          icon: 'checklist',
          text: 'All inclusive for 6 month',
        },
        subThree: {
          icon: 'expert',
          text: 'Continuous on-going care',
        },
        heading: 'Thank you for purchasing!',
        description: `You have successfully purchased ${consultationName}.`,
      };
    case 'CSTP1':
    case 'LDTP1':
    case 'VTP1':
    case 'PATP1':
    case 'PETP1':
    case 'PETP2':
    case 'PETPM1':
    case 'EDTP1':
    case 'EDTPM1':
    case 'DUA1':
    case 'DUB1':
    case 'DUBM1':
    case 'ED_M1':
      return {
        banner: 'TP',
        type: 'Allo Treatment Plan',
        suffix: 'purchase is complete',
        title: consultationName,
        subOne: {
          icon: 'medic',
          text: 'Evidence based solutions',
        },
        subTwo: {
          icon: 'checklist',
          text: 'All inclusive for 1 month',
        },
        subThree: {
          icon: 'expert',
          text: 'Continuous on-going care',
        },
        heading: 'Thank you for purchasing!',
        description: `You have successfully purchased ${consultationName}.`,
      };
    case 'FC':
      return {
        banner: CONSULTATION_CODE_DEFAULT,
        type: 'Confirm Consultation',
        title: 'Online Consultation',
        suffix: 'is scheduled',
        subOne: {
          icon: 'video-new',
          text: '1:1 private consultation',
        },
        subTwo: {
          icon: 'medic',
          text: 'Personalised treatment plan',
        },
        subThree: {
          icon: 'expert',
          text: 'Dedicated Care Expert',
        },
        heading: 'Thank you for your response!',
        description: `This will help your doctor to assist you with necessary treatment during consultation.`,
      };
    default:
      return {
        banner: CONSULTATION_CODE_DEFAULT,
        type: 'Confirm Appointment',
        title: 'Appointment',
        suffix: 'is scheduled',
        subOne: {
          icon: 'video-new',
          text: '1:1 private consultation',
        },
        subTwo: {
          icon: 'medic',
          text: 'Personalised treatment plan',
        },
        subThree: {
          icon: 'expert',
          text: 'Dedicated Care Expert',
        },
        heading: 'Thank you for your response!',
        description: `This will help your doctor to assist you with necessary treatment during consultation.`,
      };
  }
};

export const validDiagnosis = [
  'Compulsive Masturbation',
  'Couple Sex Problems',
  'Delayed Ejaculation',
  'Erectile Dysfunction',
  'Low Sexual Desire',
  'Porn Addiction',
  'Premature Ejaculation',
  'Counselling Sessions',
  'Vaginismus',
];

export const validComboPlans = [
  ['Compulsive Masturbation', 'Porn Addiction', 'Premature Ejaculation'],
  ['Compulsive Masturbation', 'Erectile Dysfunction', 'Porn Addiction'],
  ['Compulsive Masturbation', 'Erectile Dysfunction'],
  ['Compulsive Masturbation', 'Porn Addiction'],
  ['Compulsive Masturbation', 'Premature Ejaculation'],
  ['Delayed Ejaculation', 'Erectile Dysfunction'],
  ['Delayed Ejaculation', 'Low Sexual Desire'],
  ['Erectile Dysfunction', 'Low Sexual Desire'],
  ['Erectile Dysfunction', 'Porn Addiction'],
  ['Erectile Dysfunction', 'Premature Ejaculation'],
  ['Low Sexual Desire', 'Premature Ejaculation'],
  ['Porn Addiction', 'Premature Ejaculation'],
];

export const defaultBookingAmount = 199;

export const smallScreenWidth = 768;

export const assessmentPaperformCustomKey = ['root_causes', 'diagnosis_likeliness', 'plan_config', 'language'];

export const reportTypes = {
  PRESCRIPTION: 'prescription',
  ASSESSMENT: 'assessment',
  SCREENING: 'screening',
};

export const getReportData = (reportType: string = reportTypes.ASSESSMENT) => {
  return {
    subHeader: 'Your expected diagnosis came out to be',
    diagnosisHeader: '',
    validDisclaimer: 'Sexual disorders are common & treatable. More than 87% saw improvement with Allo in 6-8 weeks',
    invalidDisclaimer: '',
  };
};

export const likelinessData = {
  'Highly Likely': {
    likeliness: 'Highly Likely',
    subheader: 'High likelihood of diagnosis',
    description: 'This state means that you have major symptoms of the respective disorder.',
    image: 'likeliness-high.png',
  },
  'Less Likely': {
    likeliness: 'Less Likely',
    subheader: 'Less likely of diagnosis',
    description: 'Less likely state means that you have some symptoms of the certain disorder.',
    image: 'likeliness-less.png',
  },
  Likely: {
    likeliness: 'Likely',
    subheader: 'Diagnosis is likely',
    description: 'Likely state means that you have some symptoms of the certain disorder.',
    image: 'likeliness-less.png',
  },
  Unlikely: {
    likeliness: 'Unlikely',
    subheader: 'diagnosis Unlikely',
    description: 'Unlikely state means that you don’t have the symptoms of any disorder.',
    image: 'likeliness-none.png',
  },
};

export const rootCauseList = [
  {
    id: 1,
    title: 'Lifestyle',
    image: 'lifestyle',
    description:
      'Unhealthy lifestyles significantly affect sexual health. Appropriate blood flow is needed for an erection, and conditions such as cardiovascular disease, obesity, hypertension, and diabetes negatively impacts blood flow. Lack of physical activity and a poor diet can lead to the above conditions, which can cause erectile dysfunction.',
  },
  {
    id: 2,
    title: 'Smoking',
    image: 'smoking',
    description:
      'Smoking negatively impacts heart and blood vessel health—both significantly impact erectile function. It lowers testosterone levels, increases carbon monoxide levels, which reduce testosterone production, leading to a lower libido. Smokers, regardless of age or other factors, have an increased risk of developing ED. ',
  },
  {
    id: 3,
    title: 'Drinking',
    image: 'drinking',
    description:
      'Alcohol is a nervous system depressant; it affects respiration, nerve sensitivity, and circulation. It can lead to dehydration and decreased levels of testosterone, a crucial hormone, impacting blood and oxygen flow and causing blood vessels to dilate. Its consumption can also lead concerns such as depression and stress.',
  },
  {
    id: 4,
    title: 'Physical Health',
    image: 'physical-health',
    description:
      'There are chronic medical conditions that can negatively impact sexual health - these conditions include diabetes, obesity, hypertension, stress, depression etc. Untreated medical conditions can lead to sexual dysfunction, especially conditions such as ED and PE.',
  },
  {
    id: 5,
    title: 'Emotional Health',
    image: 'psychological',
    description:
      'Sexual and mental health are more directly linked than you think - the mind and body work together for sex to be pleasurable.Depression or anxiety can lead to sexual dysfunctions such as low sexual desire and ED and symptoms such as performance anxiety. Psychological counselling is, if prescribed, a critical and rewarding part of treatment.',
  },
  {
    id: 6,
    title: 'Relationship',
    image: 'relationship',
    description:
      'Interpersonal concerns indicates an individual may be going through a hard time with someone they care for.For example, emotional or physical concerns within a relationship can deeply affect the couple(s) sexual intimacy. This can lead to ED, PE, Vaginismus and low sexual desire.Treating these concerns equip individuals with tools to understand triggers.',
  },
];

export const contentsOffers = [
  {
    id: 1,
    title: 'Medications',
    image: 'medications',
    description:
      'Delivery of prescribed medicines to your doorstep at no additional cost in a package with no branding or name.',
  },
  {
    id: 2,
    title: 'Counselling',
    image: 'counselling',
    description:
      'Our professionals are specifically trained to create a safe space for you to address you concerns & share your inhibitions',
  },
  {
    id: 3,
    title: 'Nutrition',
    image: 'nutrition',
    description:
      'Healthy nutrition recommendations & suggestions to sustain the overall wellbeing & support other targeted treatment',
  },
  {
    id: 4,
    title: 'Exercise',
    image: 'exercise',
    description:
      'We conduct a holistic assessment of your concerns and suggest specific exercises that can help you achieve your sexual health goals',
  },
  {
    id: 5,
    title: 'Lifestyle',
    image: 'lifestyle-white',
    description:
      'Often times a small change in lifestyle goes a long way. Our experts will recommend some effective changes in your existing lifestyle to achieve sustainable improvements. ',
  },
  {
    id: 6,
    title: 'Education',
    image: 'education',
    description:
      '100% science backed resources for you to learn more about your issue, and overcome minor triggers in daily-life that amplify them.',
  },
  {
    id: 7,
    title: 'Support',
    image: 'support',
    description:
      'Our team of in-house care experts are trained to suggest protocol based suggestions for your concerns, and act as a single point of contact for an effective and discreet care.',
  },
];

export const whatsappContactLink = 'https://wa.me/+918971789457?text=';

export const SexpertAILink = 'https://www.allohealth.care/sexpertai';

export const whyTakeAssessmentVideo = 'https://www.youtube.com/embed/msRnkOv3uAA';

export const whoIsAlloVideoLink = 'https://www.youtube.com/embed/CC-jg5NtVcE';

export const testimonials = [
  {
    title: 'Discreet & timely delivery of medicines was an added bonus!',
    age: 28,
    gender: 'male',
    martialStatus: 'Unmarried',
    description:
      'I was very nervous about receiving any medications, but Allo delivered them discreetly. I was very grateful for this and was able to afford my medications with their discount. The medications were delivered within 2 days and I was able to begin following my Treatment Plan quickly',
  },
  {
    title: 'Embarrassed about Premature Ejaculation',
    age: 25,
    gender: 'male',
    martialStatus: 'In relationship',
    description:
      'I was suffering from premature ejaculation for quite some time, and decided to sign up with Allo Health. I was very uncomfortable and embarrassed in talking about it at first, but quickly felt safe because of how the process was explained to me in detail. I am happy to say that the treatment worked great for both me and my partner. Huge thanks to their care team for getting me through!',
  },
];

export enum LOCALE {
  'ENGLISH' = 'en',
  'HINDI' = 'hi',
}

export enum LANGUAGE {
  'ENGLISH' = 'English',
  'HINDI' = 'हिंदी',
}

export const blogData = [
  {
    title: 'How diet can help with ED?',
    description:
      'There are foods that improve erectile dysfunction by aiding in improved blood flow, increased testosterone......',
    image: 'allo-diet-blog.png',
    link: `/healthfeed/erectile-dysfunction/foods-and-diet-for-erectile-dysfunction`,
  },
  {
    title: 'Myths & Facts About Infertility',
    description:
      'Are you curious about infertility? Discover the truth by exploring these 5 essential Infertility facts and myths problems, provided by Allo Health.',
    image: 'allo-ed-pe-blog.png',
    link: `/healthfeed/infertility/infertility-facts-and-myths`,
  },
];

export const patientStories = [
  {
    title: 'Psychogenic Erectile Dysfunction',
    description:
      "I thought my erection problems was a one-time thing but a combination of therapy and medications. I didn't understand how therapy could help me because I didn't realize that it could have any effect on my sex life.",
    city: 'Orissa',
    age: 28,
    link: '/patient-stories/erectile-dysfunction',
    image: 'erectile-dysfunction-stories.png',
  },
  {
    title: 'Premature Ejaculation',
    description:
      'I was married for 3 months at the time I started to experience quick ejaculation. It is much more common than I thought and my one-month care plan I was able to see amazing results with my symptoms.',
    city: 'Thane',
    age: 32,
    link: '/patient-stories/premature-ejaculation',
    image: 'premature-ejaculation-stories.png',
  },
  {
    title: 'Porn Addiction & Compulsive Masturbation',
    description:
      'I had been watching porn and masturbating excessively for 3 years and recently began a relationship. When we tried to have sex, I climaxed very quickly - for 6 months. Treatment has taught me how to control my impulses.',
    city: 'West Bengal',
    age: 25,
    link: '/patient-stories/porn-addiction-compulsive-masturbation',
    image: 'porn-addiction-stories.png',
  },
  {
    title: 'Vaginismus',
    description:
      "When I was experiencing the symptoms of Vaginismus for almost a year, I had no idea about how the 'normal' female anatomy. Fast forward to now, and educating myself and going through therapy is one of the best things I had done.",
    city: 'Meerut',
    age: 28,
    link: '/patient-stories/vaginismus',
    image: 'vaginismus-stories.png',
  },
  {
    title: 'Couple Sex Problems - Unconsummated Marriage',
    description:
      "We learned how sexual intimacy is a big part of a couple's sex life. Sex isn't just a physical activity, rather, it requires the mind and body to connect - we had lost focus of this, and now we can say this is all we focus on.",
    city: 'Shimla',
    age: 32,
    link: '/patient-stories/couple-sex-problems',
    image: 'couple-sex-problems-stories.png',
  },
];

export const handbookBlogs: IResourceData[] = [
  {
    id: '',
    badge: '',
    type: 'blog',
    title: 'Is your lack of Sexual Drive normal?',
    description:
      'Low sexual desire is decrease in sex drive. The course involve physical, mental, emotional factors that results in different symptoms. It is also called Low Libido. It can be influenced...',
    imageUrl: 'couple-sex-problems-stories.png',
    label: 'Anoush Gomes 20-12-2022',
    category: 'Low Sexual Desire',
    externalUrl: '/healthfeed/sexual-wellness/is-a-low-sex-drive-normal',
  },
  {
    id: '',
    badge: '',
    type: 'blog',
    title: 'Porn Addiction & Sexual Dysfunction',
    description:
      'Low sexual desire is decrease in sex drive. The course involve physical, mental, emotional factors that results in different symptoms. It is also called Low Libido.',
    imageUrl: 'porn-addiction-stories.png',
    label: 'Anoush Gomes 03-01-2023',
    category: 'Porn Addiction',
    externalUrl: '/healthfeed/porn-addiction/effects-of-porn-addiction',
  },
];

export const timePeriods = {
  'All Slots': {
    start: 7,
    end: 23,
  },
  Morning: {
    start: 7,
    end: 13,
  },
  Afternoon: {
    start: 13,
    end: 17,
  },
  Evening: {
    start: 17,
    end: 23,
  },
};

export const prescriptionTypes = {
  MEDICATIONS: 'medications',
  COUNSELLING: 'sex counselling',
  TESTS: 'diagnostic tests',
};

export const adviceTypes = {
  provider_advice: 'General Advice',
  provider_advice_pe: 'Advice on Premature Ejaculation',
  provider_advice_ed: 'Advice on Erectile Dysfunction',
  provider_advice_porn_addiction: 'Advice on Porn Addiction',
  provider_advice_compulsive_masturbation: 'Advice on Compulsive Masturbation',
  provider_advice_de: 'Advice on Delayed Ejaculation',
  provider_advice_low_desire: 'Advice on Low Sexual Desire',
  provider_advice_vaginismus: 'Advice on Vaginismus',
  provider_advice_fsad: 'Advice on Female Sexual Arousal Disorder',
  provider_advice_other: 'Other advice',
  provider_advice_sti: 'Advice on Sexually Transmitted Infections',
};

export const appointmentTypes = {
  CONSULTATION: 'Consultation',
  SCREENING_CALL: 'Screening Call',
  FIRST_CONSULTATION: 'First Consultation',
  FOLLOW_UP: 'Follow Up',
  OFFLINE: 'Offline',
  THERAPY: 'Therapy',
  RECONSULTATION: 'Reconsultation',
  RESCHEDULE: 'Reschedule',
  OFFLINE_THERAPY: 'Offline Therapy',
  REPORT_READING: 'Report Reading',
};

export const RESCHEDULE_REASON = 'Patient rescheduled via Confirmation page';

export const rxTypes = {
  SCREENING_RX: 'screening-rx',
  CONSULTATION_RX: 'consultation-rx',
  MERGE_RX: 'merged-rx',
  TAKE_AX: 'take-ax',
  COUNSELLING_RX: 'counselling-rx',
  SCREENING_AX: 'screening-ax',
};

export const metaDataTypes = {
  PRECAUTION: 'precautions',
  SIDE_EFFECT: 'side_effects',
  USE: 'use',
  FAQ: 'faq',
  EFFECT: 'effects',
  BASIC_INFO: 'basic_info',
};

export const metaDataSequence = {
  [metaDataTypes.BASIC_INFO]: 1,
  [metaDataTypes.USE]: 2,
  [metaDataTypes.PRECAUTION]: 3,
  [metaDataTypes.EFFECT]: 4,
  [metaDataTypes.SIDE_EFFECT]: 5,
  [metaDataTypes.FAQ]: 6,
};

export const COUNSELLING_CUSTOM_KEYS = {
  COUNSELLING_GOALS_DICTIONARY: 'counselling_goals_dictionary',
};

export const formFeatures = [
  {
    feature: 'Best use of time',
    icon: 'stopwatch.svg',
  },
  {
    feature: '100% confidential',
    icon: 'security-checked.svg',
  },
  {
    feature: 'More holistic expert discussion',
    icon: 'user-music.svg',
  },
  {
    feature: 'No stressing about important details',
    icon: 'info-cloud.svg',
  },
];

export const bannerTypes = {
  TP: 'TP',
  SC: 'SC',
};

export const orderStatus = {
  ORDER_PLACED: 'Received Order',
  ORDER_PACKED: 'Pending Dispatch',
  DISPATCHED: 'On the Way',
  DELIVERED: 'Delivered',
};

export const labOrderStatus = {
  ORDER_PLACED: 'Pending Collection',
  DISPATCHED: 'On the Way',
  SAMPLE_COLLECTED: 'Sample Collected',
  REPORT_GENERATED: 'Report Generated',
};

export const orderTrackingBaseUrl = 'https://shiprocket.co/tracking/';

//order matters
export const PAGE = {
  DASHBOARD: {
    NAME: 'DASHBOARD',
    ROUTE: 'dashboard',
  },
  FEEDBACK: {
    NAME: 'FEEDBACK',
    ROUTE: 'feedback',
  },
  APPOINTMENTS: {
    NAME: 'APPOINTMENTS',
    ROUTE: 'appointments',
  },
  APPOINTMENT: {
    NAME: 'APPOINTMENT',
    ROUTE: 'appointment',
  },
  SCHEDULE: {
    NAME: 'SCHEDULE',
    ROUTE: 'schedule',
  },
  CONFIRMATION: {
    NAME: 'CONFIRMATION',
    ROUTE: 'confirmation',
  },
  ASSESSMENT_REPORT: {
    NAME: 'ASSESSMENT_REPORT',
    ROUTE: 'assessment-report',
  },
  ASSESSMENT: {
    NAME: 'ASSESSMENT',
    ROUTE: 'assessment',
  },
  LATEST_RECORD: {
    NAME: 'LATEST_RECORD',
    ROUTE: 'records/latest',
  },
  RECORDS: {
    NAME: 'RECORDS',
    ROUTE: 'records',
  },
  CX_JOURNEY: {
    NAME: 'CX JOURNEY',
    ROUTE: 'cx-journey',
  },
  PURCHASE: {
    NAME: 'PURCHASE',
    ROUTE: 'purchase',
  },
  LOGIN: {
    NAME: 'LOGIN',
    ROUTE: 'login',
  },
  LOGOUT: {
    NAME: 'LOGOUT',
    ROUTE: 'logout',
  },
  PHMS: {
    NAME: 'PHMS',
    ROUTE: 'provider-meet',
  },
  HMS: {
    NAME: 'HMS',
    ROUTE: 'meet',
  },
  REDIRECT: {
    NAME: 'REDIRECT',
    ROUTE: 'redirect',
  },
  RESOURCES: {
    NAME: 'RESOURCES',
    ROUTE: 'resources',
  },
  AUTHORIZE: {
    NAME: 'AUTHORIZE',
    ROUTE: 'authorize',
  },
  PREPORT: {
    NAME: 'PREPORT',
    ROUTE: 'prescription-report',
  },
  REPORT: {
    NAME: 'REPORT',
    ROUTE: 'report',
  },
  PRINT_PRESCRIPTION: {
    NAME: 'PRINT_PRESCRIPTION',
    ROUTE: 'print-prescription',
  },
  ACTIVITY: {
    NAME: 'ACTIVITY',
    ROUTE: 'profile/activity',
  },
  PROFILE: {
    NAME: 'PROFILE',
    ROUTE: 'profile',
  },
  MEDICINES: {
    NAME: 'MEDICINES',
    ROUTE: 'medicines',
  },
  LAB_TESTS: {
    NAME: 'LAB_TESTS',
    ROUTE: 'lab-tests',
  },
  DASHBOARDV2: {
    NAME: 'DASHBOARDv2',
    ROUTE: 'dashboardV2',
  },
};

export const TRANSITION_PAGES = [PAGE.LOGIN, PAGE.LOGOUT, PAGE.AUTHORIZE, PAGE.REDIRECT];

export const ROUTE_NAVIGATION_NAME = {
  DASHBOARD: 'DASHBOARD',
  PREPORT: 'PREPORT',
  RECORDS: 'RECORDS',
  ACTIVITY: 'ACTIVITY',
  APPOINTMENT: 'APPOINTMENT',
  MEDICINES: 'MEDICINES',
  LAB_TESTS: 'LAB_TESTS',
  REPORT: 'REPORT',
  DASHBOARDV2: 'DASHBOARDV2',
};

export const ROUTE_NAVIGATION = {
  DASHBOARD: {
    name: 'Dashboard',
    route: '/dashboard',
    activeIcon: 'home-purple.svg',
    inactiveIcon: 'home.svg',
    icon: 'home',
  },
  PREPORT: {
    name: 'Prescription',
    route: '/prescription-report',
    activeIcon: 'document-purple.svg',
    inactiveIcon: 'document.svg',
  },
  RECORDS: {
    name: 'My Records',
    route: '/records',
    activeIcon: 'record-purple.svg',
    inactiveIcon: 'record.svg',
  },
  ACTIVITY: {
    name: 'Activity',
    route: '/profile/activity',
    activeIcon: 'activity-purple.svg',
    inactiveIcon: 'activity-grey.svg',
  },
  APPOINTMENT: {
    name: 'Appointments',
    route: '/appointments',
    icon: 'stethoscope',
  },
  MEDICINES: {
    name: 'Medicines',
    route: '/medicines',
    icon: 'medication',
  },
  LAB_TESTS: {
    name: 'Lab Tests',
    route: '/lab-tests',
    icon: 'Labs',
  },
  REPORT: {
    name: 'Report',
    route: '/reports',
    icon: 'lab_profile',
  },
  DASHBOARDV2: {
    name: 'Dashboard',
    route: '/dashboard-v2',
    icon: 'home',
    activeIcon: 'home-purple.svg',
    inactiveIcon: 'home.svg',
  },
};

export const alloPhoneNumber = '+918071175797';
export const alloWhatsAppNumber = '+918618365103';
export const alloInstagramHandle = '@allohealth.care';

export const LanguageTranslations = {
  bangla: 'বাংলা',
  bengali: 'বাংলা',
  gujrati: 'ગુજરાતી',
  gujarati: 'ગુજરાતી',
  hindi: 'हिंदी',
  kannada: 'ಕನ್ನಡ',
  malayalam: 'മലയാളം',
  marathi: 'मराठी',
  punjabi: 'ਪੰਜਾਬੀ',
  tamil: 'தமிழ்',
  telugu: 'తెలుగు',
  english: 'English',
  french: 'Français',
  spanish: 'Español',
  german: 'Deutsch',
  italian: 'Italiano',
  portuguese: 'Português',
  russian: 'русский',
  chinese: '中文',
  japanese: '日本語',
  korean: '한국어',
  arabic: 'العربية',
  turkish: 'Türkçe',
  thai: 'ไทย',
  indonesian: 'Bahasa Indonesia',
  vietnamese: 'Tiếng Việt',
  polish: 'Polskie',
  dutch: 'Nederlands',
  swedish: 'Svenska',
  norwegian: 'Norsk',
  finnish: 'Suomalainen',
  danish: 'Dansk',
  czech: 'Český',
  hungarian: 'Magyar',
  konkani: 'कोंकणी',
};

export const CONSULTATION_TYPE = {
  FU: 'FU',
  SC: 'SC',
  FC: 'FC',
  TH: 'TH',
  PQ: 'PQ',
  RR: 'RR',
};

export const CONSULTATION_STATE = {
  RESCHEDULE: 'reschedule',
  SCHEDULE: 'schedule',
  RECONSULT: 'reconsult',
};

export enum UserRole {
  PATIENT = 'patient',
  PROVIDER = 'provider',
  AGENT = 'agent',
  SUPERADMIN = 'superadmin',
}

export const DEFAULT_CITY = 'Bangalore';

export enum frequencyValues {
  daily = 'daily',
  ondemand = 'as needed',
  dual = 'alternate day',
  onceAWeek = 'once a week',
  onceInThreeDays = 'once in 3 days',
}

export enum CONSULTATION_NAMES {
  FU = 'Follow Up',
  SC = 'Screening Call',
  FC = 'First Consultation',
  TH = 'Therapy',
  PQ = 'Patient Queries',
  RR = 'Report Reading',
}

export enum WeekDays {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const BENEFITS_OF_CONSULTATION = [
  {
    title: 'Treatment the matches your needs',
    description: 'We will figuring out the course of the treatment or the right doctor for you',
  },
  {
    title: 'Get reliable treatments',
    description: 'Take the first step to a reliable diagnosis.',
  },
  {
    title: 'Stop Googling your symptoms',
    description: 'Get clean diagnosis of your symptoms with experienced doctors',
  },
];

export const callInstructions: {
  instrction: string;
  images?: { src: string; height: number; width: number }[];
}[] = [
  {
    instrction: 'Grant audio and video permissions for your respective device.',
    images: [
      {
        src: '/assets/microphone-permission-message.png',
        width: 152,
        height: 90,
      },
      {
        src: '/assets/camera-permission-message.png',
        width: 152,
        height: 90,
      },
    ],
  },
  {
    instrction: 'Keep your valid ID proofs handy before joining the call.',
  },
  {
    instrction: 'Share you old relevant reports with us before the consultation call.',
  },
  {
    instrction: 'Switch to Chrome for a better experience.',
    images: [
      {
        src: '/assets/svgs/chrome-cursor.svg',
        width: 62,
        height: 62,
      },
    ],
  },
  {
    instrction: 'Ensure good internet connectivity.',
    images: [
      {
        src: '/assets/svgs/good-connectivity.svg',
        width: 180,
        height: 134,
      },
    ],
  },
  {
    instrction:
      'All Allo sessions are recorded for best consultation experience - 100% confidential and secure. Disable/enable this anytime under `Settings`.',
    images: [
      {
        src: '/assets/recording-toggle.png',
        width: 210,
        height: 160,
      },
    ],
  },
];

export const WHY_CHOOSE_ALLO = [
  {
    title: 'You are not alone with Allo Health',
    description: 'We will figure out the right course of treatment with a right doctor for you.',
  },
  {
    title: 'Problem is fixable',
    description: 'Take the first step to a reliable diagnosis and treatment.',
  },
  {
    title: 'Why now?',
    description: 'Get clean diagnosis of your symptoms with experienced team of doctors.',
  },
];

export const BENEFITS_OF_TREATMENT = [
  'Personalised to your needs',
  'On call support whenever you need',
  'Progress checkin after 15 days',
  'Upgrade treatment plan in between',
  'Alter treatment post improvements',
];

export const WHAT_TO_AVOID = [
  {
    icon: '/assets/svgs/drug-cancel.svg',
    title: 'what-to-avoid.self-medication',
  },
  {
    icon: '/assets/svgs/seeking-frame.svg',
    title: 'what-to-avoid.delay-in-seeking-help',
  },
  {
    icon: '/assets/svgs/suppliment-cancel.svg',
    title: 'what-to-avoid.unverified-supplements',
  },
];

export const REVIEWS = [
  {
    icon: '/assets/practo.png',
    title: 'Practo',
    rating: 4.9,
    svg: '/assets/svgs/star.svg',
  },
  {
    icon: '/assets/google.png',
    title: 'Google',
    rating: 4.7,
    svg: '/assets/svgs/star.svg',
  },
];

export const ELLIPSE_REVIEWS = [
  {
    icon: '/assets/star-ellipse.png',
    title: 'star',
    rating: '4.7/5',
    description: ' ellipse-reviews.average-consultation-ratings-with-allo', //translatory
  },
  {
    icon: '/assets/cross-ellipse.png',
    title: 'cross',
    rating: '87%',
    description: ' ellipse-reviews.users-saw-significant-improvements', //translatory
  },
];

export const TRACK_ORDERS = [
  {
    title: 'left-view',
    icon: '/assets/track-phone-left.png',
  },
  {
    title: 'middle-view',
    icon: '/assets/track-phone-middle.png',
  },
  {
    title: 'right-view',
    icon: '/assets/track-phone-right.png',
  },
];

export const ALLOWED_UTM_SOURCES_FOR_V2 = ['ekkacare'];

export const APPOINTMENT_BLOCKER_MESSAGE = [
  'Reliable Medical Advice',
  'Convenient, Confidential Consultation',
  'Easy to Start Action Plan',
];

export const TRUST_MAKERS = [
  {
    id: 1,
    image: '/assets/svgs/medically-backed.svg',
    title: 'about-trust-markers.medically-backed', //translatory
    rating: '100%',
  },
  {
    id: 2,
    image: '/assets/svgs/patients-treated.svg',
    title: 'about-trust-markers.patients-treated', //translatory
    rating: '30,000+',
  },
  {
    id: 3,
    image: '/assets/svgs/stethoscope-primary.svg',
    title: 'about-trust-markers.medical-expertise', //translatory
    rating: 'Top 1%',
  },
];

export const MEDIA_GRID = [
  {
    id: 1,
    image: '/assets/media-grid/economic-times.png',
    width: 284,
    height: 40,
  },
  {
    id: 2,
    image: '/assets/media-grid/money-control.png',
    width: 248,
    height: 56,
  },
  {
    id: 3,
    image: '/assets/media-grid/femina.svg',
    width: 140,
    height: 24,
  },
  {
    id: 4,
    image: '/assets/media-grid/indian-express.png',
    width: 276,
    height: 36,
  },
  {
    id: 5,
    image: '/assets/media-grid/abp.png',
    width: 116,
    height: 48,
  },
  {
    id: 6,
    image: '/assets/media-grid/times-of-india.png',
    width: 284,
    height: 20,
  },
  {
    id: 7,
    image: '/assets/media-grid/deccan-herald.png',
    width: 224,
    height: 64,
  },
];

export const SLOT_SELECTION = {
  DEFAULT: 'default_slot',
  SELECTED: 'slot_selected',
  SLOT_CONFIRMED: 'slot_confirmed',
  PAYMENT_COMPLETED: 'payment_completed',
};

export const WHAT_SET_ALLO_APART = [
  {
    image: '/assets/svgs/physics-yellow.svg',
    title: "Allo's Personalised Platform",
    description: 'Tracking & assessing your progress',
  },
  {
    image: '/assets/svgs/following-yellow.svg',
    title: 'Access To Professional Support',
    description: 'Ensures safe and effective learning',
  },
  {
    image: '/assets/svgs/stethoscope-yellow.svg',
    title: 'Exclusive Guidance',
    description: 'Video concept to support your journey',
  },
];

export const PRESCRIPTION_REPORT_ED_DATA = [
  {
    id: 1,
    heading: 'Performance Building Session',
    subHeading: 'After this session you will be able to',
    description: [
      'Perform exercises to improve sexual performance',
      'Engage in intimacy-building exercises to deepen sexual connection with Partner',
      'Master techniques to enhance sexual potential',
    ],
  },
  {
    id: 2,
    heading: 'Confidence Building Session',
    subHeading: 'This session will',
    description: [
      'Boost body confidence for higher self-esteem',
      'Learn effective communication skills for healthier sexual relationships',
      'Access a safe space for discussing taboo topics and doubts',
    ],
  },
];

export const PRESCRIPTION_REPORT_PE_DATA = [
  {
    id: 1,
    mediaLink: 'https://www.youtube.com/embed/iEWgtHoxdUw',
    heading: 'Will address Premature Ejaculation concerns',
    subHeading: 'What to expect',
    description: [
      'Personalised plan to overcome PE, tailored to your needs. Step-by-Step guide to ‘Allo Stop-Start Technique.',
      'Understand psychological and physiological factors affecting PE.Integrate technique seamlessly into your routine.',
      'Access to real professionals who provide direct support.',
    ],
  },
  {
    id: 2,
    mediaLink: 'https://www.youtube.com/embed/SB68aGCkA_U',
    heading: 'Will evaluate progress & enhanced recovery',
    subHeading: 'What to expect',
    description: [
      'Assessment of progress since the first session.Introduction to Allo’s ‘Advanced Pelvic Floor Technique’ for further improvement.',
      'Deeper exploration of pelvic floor exercises and control techniques.',
      'Guidance from professionals to ensure effective learning and practice.',
    ],
  },
];

export const DEFAULT_LOCATION_CODE = 'ONLINE';

export const CONSULTATION_INVOICE_GUIDLINES = [
  'Healthcare services are exempt from GST, hence no GST is leviable',
  'The services are provided by an independent Doctor, Allo health acts as an Aggregator for the aforementioned services.',
  'This is an Auto generated document & does not require any signature.',
];

export const COUNSELLING_INVOICE_GUIDLINES = [
  'Healthcare services are exempt from GST, hence no GST is leviable',
  'The services are provided by an independent Counsellor, Allo health acts as an Aggregator for the aforementioned services.',
  'This is an Auto generated document & does not require any signature.',
];

export const DRUG_INVOICE_GUIDLINES = [
  ' The goods are provided by pharmacy, Allo health acts as an Aggregator for the aforementioned goods.',
  'This is an Auto generated document & does not require any signature.',
];

export const TESTS_INVOICE_GUIDLINES = [
  'Healthcare services are exempt from GST, hence no GST is leviable.',
  'The services are provided by an independent Partner, Allo health acts as an Aggregator for the aforementioned services.',
  'This is an Auto generated document & does not require any signature.',
];

export const PSEUDO_COUPON_CODE = 'ALLOSPECIAL';

export enum ItemType {
  DRUG = 'drug',
  LAB = 'lab',
  COUNSELLING = 'counselling',
  CONSULTATION = 'consultation',
}

export const DUMMY_CLINIC = {
  id: '01cd5650-832d-4b56-a03f-d72cd05d6107',
  address: 'No 31, 80 Feet Rd, Hal, HAL 3rd Stage, Indiranagar, Bengaluru, Karnataka 560038',
  city: 'Bangalore',
  locality: 'Clinic',
  type: 'dummy',
  name: 'Allo Health Clinic',
  code: 'DUMMY',
  imageLinks: ['https://assets-global.website-files.com/61b07a34fa095691fd2a31c5/6645d3666aa869213f583ec3_5%20(2).png'],
  latitude: 12.972_022_56,
  longitude: 77.647_374_07,
  totalRating: 450,
  rating: '4.2+',
};

export enum ClinicFilters {
  'All' = 'All',
  'Female Doctors' = 'Female Doctors',
  'Male Doctors' = 'Male Doctors',
  'Nearest Doctors' = 'Nearest Doctors',
  'Highly Rated' = 'Highly Rated',
  'English Speaking' = 'English Speaking',
}

export enum ReportCategory {
  PRESCRIPTION = 'Prescriptions',
  LABREPORT = 'Lab Reports',
  PASTREPORTS = 'Past reports',
}

export const IconMap = new Map([
  [CONSULTATION_TYPE.SC, 'stethoscope'],
  [CONSULTATION_TYPE.PQ, 'stethoscope'],
  [CONSULTATION_TYPE.FU, 'schedule'],
  [CONSULTATION_TYPE.TH, 'chart_data'],
  [CONSULTATION_TYPE.RR, 'Labs'],
]);

export const navigationArray = [
  { NAME: 'DASHBOARD' },
  { NAME: 'APPOINTMENT' },
  { NAME: 'MEDICINES' },
  { NAME: 'LAB_TESTS' },
  { NAME: 'REPORT' },
];
