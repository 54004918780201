import Cookies from 'js-cookie';
import { useRef } from 'react';

import { BillingActions, CheckoutActions, CommonActions } from '@Actions/index';
import { IAnalytics } from '@Reducers/analytics';
import { IConsultationInvoice } from '@Reducers/billing';
import { IQueryData } from '@Reducers/common';
import { ISlot } from '@Reducers/schedule';
import { IUserData } from '@Reducers/user';
import { useAppDispatch, useAppSelector } from '@Redux/hooks';
import { InvoiceMode } from '@Services/Billing/billing-payload';
import * as gtm from '@Utils/analytics/gtm';
import { ONLINE_LOCATION_ID, SLOT_SELECTION } from '@Utils/constants';
import { decodeCookie } from '@Utils/helper';

import useBookInstantConsultation from './book-instant-consultation';
import usePayment from './payment';
import useSchedulingFailure from './scheduling-failure';

interface IBookConsultation {
  selectedSlot: ISlot;
  payOnArrival: boolean;
  activity?: number;
  activityNote?: string;
  getSlots: () => void;
  trackSlots: (event: string) => void;
  typeId?: string;
  locationId?: string;
  noHold?: boolean;
}

const useBookConsultation = () => {
  const dispatch = useAppDispatch();
  const openPaymentModal = usePayment();
  const schedulingFailure = useSchedulingFailure();
  const bookInstantConsultation = useBookInstantConsultation();

  const userData: IUserData = useAppSelector((state) => state.user.userData);
  const queryData: IQueryData = useAppSelector((state) => state.common.queryData);
  const queryDataRef = useRef(null);
  queryDataRef.current = queryData;
  const consultationInvoicePreview: IConsultationInvoice = useAppSelector(
    (state) => state.billing.consultationInvoicePreview,
  );

  const invoice: IConsultationInvoice = useAppSelector((state) => state.billing.consultationInvoice);
  const invoiceRef = useRef(null);
  invoiceRef.current = invoice;

  const analytics: IAnalytics = useAppSelector((state) => state.analytics);

  const bookConsultation = ({
    selectedSlot,
    payOnArrival,
    getSlots,
    trackSlots,
    typeId,
    locationId,
    noHold = false,
  }: IBookConsultation) => {
    const hasSelectedSlot = selectedSlot?.endDate?.length > 0;
    if (!hasSelectedSlot && !queryDataRef.current?.isInstant) return;

    if (!queryDataRef.current?.isInstant && hasSelectedSlot) {
      trackSlots(SLOT_SELECTION.SLOT_CONFIRMED);
    }
    if (queryDataRef.current?.isInstant) {
      bookInstantConsultation(typeId);
    } else {
      generateInvoice(selectedSlot, payOnArrival, getSlots, typeId, locationId, noHold);
      dispatch(
        CommonActions.UpdateQueryData({
          date: selectedSlot?.startDate,
          selected_languages: queryDataRef.current.selectedLanguages,
        }),
      );
    }
  };

  const generateInvoice = (
    selectedSlot: ISlot,
    payOnArrival: boolean,
    getslots: () => void,
    typeId?: string,
    locationId?: string,
    noHold?: boolean,
  ) => {
    const payload = {
      typeId: typeId,
      locationId: locationId,
      providerIds: queryDataRef.current?.providerId,
      excludedProviderIds: queryDataRef.current?.excludedProviderId,
      mode: payOnArrival ? InvoiceMode.Postpaid : InvoiceMode.Prepaid,
      startTime: selectedSlot?.startDate,
      endTime: selectedSlot?.endDate,
      blockId: selectedSlot?.blockId,
      preferredLanguages: locationId === ONLINE_LOCATION_ID ? queryDataRef.current.selectedLanguages : undefined,
      voucherCode: locationId === ONLINE_LOCATION_ID ? consultationInvoicePreview?.voucherCode : undefined,
      ...(queryDataRef?.current?.surcharge > 0 && {
        attributes: {
          surchargeRate: queryDataRef?.current?.surcharge,
        },
      }),
    };
    dispatch(BillingActions.CreateConsultationInvoiceFuture(payload, noHold))
      .then(() => {
        dispatch(CheckoutActions.TogglePauseSlotRelease());
        openPaymentModal({
          itemType: 'consultation',
          onDismissFunction: () => dispatch(CheckoutActions.TogglePauseSlotRelease()),
          onSuccess: () => dispatch(CheckoutActions.TogglePauseSlotRelease()),
          onFailFunction: () => dispatch(CheckoutActions.TogglePauseSlotRelease()),
        });
        dispatch(CommonActions.ResetbookCallParams());
        if (locationId == ONLINE_LOCATION_ID || payOnArrival) {
          gtm.userEvent({
            event: 'appointment_booked',
            phone: userData.phoneNumber,
            // eslint-disable-next-line unicorn/numeric-separators-style
            amount: 135700,
            utm_source: decodeCookie(Cookies.get('utm_source')) || analytics?.leadData?.utmSource,
            utm_campaign: decodeCookie(Cookies.get('utm_campaign')) || analytics?.leadData?.utmCampaign,
            acq_source: analytics?.leadData?.utmSource || decodeCookie(Cookies.get('utm_source')),
            fbclid: decodeCookie(Cookies.get('fbclid')),
            invoiceId: invoiceRef.current?.id,
            userId: userData.id,
          });
        }
        return;
      })
      .catch(() => {
        schedulingFailure(() => getslots());
        dispatch(CommonActions.ResetbookCallParams());
      });
  };

  return bookConsultation;
};

export default useBookConsultation;
