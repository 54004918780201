import { InvoiceMode } from '@Services/Billing/billing-payload';
import { IAddress } from '@Services/Patient/patient-payload';
import { FileType } from '@Utils/Helpers/PatientFiles/helper';

export interface UpdateQueryDataPayload {
  ipx_id?: string;
  provider_id?: string;
  excluded_provider_id?: string;
  reason?: string;
  providerIds?: string; // backward compatible
  voucher_code?: string;
  product_code?: string;
  phone?: string;
  email?: string;
  name?: string;
  date?: string;
  appointment_id?: string;
  consultation_id?: string;
  appointment_state?: string;
  page_source?: string;
  source_url?: string;
  location_code?: string;
  token?: string;
  form_type?: string;
  selected_languages?: string[]; //temporary
  enc_id?: string;
  encounter_id?: string; //backward compatible
  consultation_state?: string;
  confirmation_type?: string; //TODO: add enum
  utm_term?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  gclid?: string;
  fbclid?: string;
  fbc?: string;
  fbp?: string;
  embedded?: string;
  assessment_language?: string;
  consultation_mode?: ConsultationMode;
  invoice_mode?: InvoiceMode;
  valid_user_city?: string;
  speciality?: string;
  inventory_item_id?: string;
  inventory_item_type?: string;
  is_instant?: boolean;
  surcharge?: number;
  open_payment_modal?: boolean;
  block_id?: string;
  start_time?: string;
  end_time?: string;
  type_id?: string;
  lab_venue?: string;
  schedule_type?: ScheduleType;
  selected_address?: IAddress;
  collection_date?: string;
  collection_slot_id?: number;
  upload_type?: FileType;
}

export enum ScheduleType {
  Consultation = 'consultation',
  Lab = 'lab',
}

export enum ConsultationMode {
  OFFLINE = 'offline',
  ONLINE = 'online',
}
