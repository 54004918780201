import Image from 'next/image';
import React, { useState } from 'react';

import { ColorType } from '@Components/common-exports';
import { colors } from '@Components/design-system/color';

const DoctorImage = ({
  doctorImage = '',
  size,
  backgroundColor = ColorType.BLUE_GREY_100,
  backgroundShape = 'circle',
}: {
  doctorImage: string;
  size: number;
  backgroundColor?: ColorType;
  backgroundShape?: string;
}) => {
  const [isImageValid, setIsImageValid] = useState(true);
  const borderRadiusClass = backgroundShape === 'circle' ? 'rounded-full' : 'rounded-lg';

  return (
    <Image
      className={`${borderRadiusClass} shadow-md`}
      style={{ backgroundColor: colors[backgroundColor] }}
      src={isImageValid && doctorImage ? `${doctorImage}` : '/assets/svgs/avatar.svg'}
      onError={() => setIsImageValid(false)}
      width={size}
      height={size}
      layout='fixed'
      alt='allo-icon'
    />
  );
};

export default DoctorImage;
