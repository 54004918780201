import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import { useState } from 'react';

import { PatientService } from '@Services';
import { LOG_ERROR } from '@Utils/logger';

const usePatientEncounters = () => {
  const [loading, setLoading] = useState(true);

  const fetchPatientEncounterId = async (requestPayload) => {
    try {
      setLoading(true);
      const result = await PatientService.GetPatientEncounters(requestPayload);
      return result.data[0]?.id;
    } catch (error) {
      LOG_ERROR('fetchPatientEncountersbyAppointmentId', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchPatientEncounterId,
    loading,
  };
};

export default usePatientEncounters;
