import React from 'react';

import { IQueryData } from '@Reducers/common';
import { ILocationProvidersMap } from '@Reducers/location';
import { IProviderData } from '@Reducers/provider';
import { IClinicLocationsData, ISlot } from '@Reducers/schedule';
import { useAppSelector } from '@Redux/hooks';
import { DEFAULT_LOCATION_CODE } from '@Utils/constants';

import NoLocationGiven from './consultation-options/no-location-given';
import Offline from './consultation-options/offline';
import Online from './consultation-options/online';

const ConsultationOptions = ({
  clinicLocations,
  browseClinics,
  changeLocation,
  onlineInstantPreviewSlots,
}: {
  clinicLocations: Record<string, IClinicLocationsData[]>;
  browseClinics: () => void;
  changeLocation: (locationCode: string) => void;
  onlineInstantPreviewSlots: ISlot[];
}) => {
  const queryData: IQueryData = useAppSelector((state) => state.common?.queryData);
  const validUserCity: string = queryData?.validUserCity;
  const allProviderData: Record<string, IProviderData> = useAppSelector((state) => state.provider.allProviderData);
  const locationProviders: ILocationProvidersMap = useAppSelector((state) => state.location.locationProvidersMap);
  const clinic: IClinicLocationsData =
    !queryData?.locationCode || queryData?.locationCode === DEFAULT_LOCATION_CODE
      ? clinicLocations?.[queryData?.validUserCity]?.[0]
      : clinicLocations?.[queryData?.validUserCity]?.find((clinic) => clinic.code === queryData?.locationCode);
  const notAllowedToBookOffline = useAppSelector((state) => state.schedule.hideOfflineLocation);
  const notAllowedToBookOnline = useAppSelector((state) => state.schedule.hideOnlineLocation);

  return (
    <>
      {Object.keys(clinicLocations || {}).length > 0 && !notAllowedToBookOffline ? (
        validUserCity && clinic?.id ? (
          <Offline
            nearestClinic={clinic}
            clinicsLocalities={clinicLocations?.[queryData?.validUserCity]?.map((city) => city?.locality)}
            clincProviders={getProvidersForLocation(clinic?.id, allProviderData, locationProviders)}
            browseClinics={browseClinics}
            changeLocation={changeLocation}
            isSelected={queryData?.locationCode !== DEFAULT_LOCATION_CODE}
          />
        ) : (
          <NoLocationGiven
            browseClinics={browseClinics}
            isSelected={queryData?.locationCode !== DEFAULT_LOCATION_CODE}
          />
        )
      ) : (
        <></>
      )}
      {!notAllowedToBookOnline && (
        <Online
          changeLocation={changeLocation}
          onlineInstantPreviewSlots={onlineInstantPreviewSlots}
          isSelected={queryData?.locationCode === DEFAULT_LOCATION_CODE}
        />
      )}
    </>
  );
};

export default ConsultationOptions;

export const getProvidersForLocation = (
  locationId: string,
  allProviderData: Record<string, IProviderData>,
  locationProviders: ILocationProvidersMap,
) => {
  return locationProviders?.[locationId]?.map((providerId) => allProviderData[providerId]);
};
