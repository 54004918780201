import { BillingActions, CommonActions } from '@Actions/index';
import { useAppDispatch, useAppSelector } from '@Redux/hooks';
import { BillingSource, InvoiceMode } from '@Services/Billing/billing-payload';
import usePayment from './payment';
import { IUserData } from '@Reducers/user';
import { decodeCookie } from '@Utils/helper';
import Cookies from 'js-cookie';
import { IAnalytics } from '@Reducers/analytics';
import * as gtm from '@Utils/analytics/gtm';
import { IConsultationInvoice } from '@Reducers/billing';
import { useCallback, useRef } from 'react';
import { LOG_ERROR } from '@Utils/logger';

const useBookInstantConsultation = () => {
  const dispatch = useAppDispatch();
  const openPaymentModal = usePayment();
  const userData: IUserData = useAppSelector((state) => state.user.userData);
  const analytics: IAnalytics = useAppSelector((state) => state.analytics);
  const invoice: IConsultationInvoice = useAppSelector((state) => state.billing.consultationInvoice);
  const invoiceRef = useRef(null);
  invoiceRef.current = invoice;

  const bookInstantConsultation = useCallback(
    (typeId: string) => {
      dispatch(
        BillingActions.CreateInstantConsultationInvoice({
          typeId: typeId,
          mode: InvoiceMode.Prepaid,
          providerIds: [],
          billingSource: BillingSource.ALLO_INSTANT,
          startTime: new Date().toISOString(),
        }),
      )
        .then(() => {
          openPaymentModal({ itemType: 'consultation' });
          gtm.userEvent({
            event: 'appointment_booked',
            phone: userData.phoneNumber,
            // eslint-disable-next-line unicorn/numeric-separators-style
            amount: 135700,
            utm_source: decodeCookie(Cookies.get('utm_source')) || analytics?.leadData?.utmSource,
            utm_campaign: decodeCookie(Cookies.get('utm_campaign')) || analytics?.leadData?.utmCampaign,
            acq_source: analytics?.leadData?.utmSource || decodeCookie(Cookies.get('utm_source')),
            fbclid: decodeCookie(Cookies.get('fbclid')),
            invoiceId: invoiceRef.current?.id,
            userId: userData.id,
          });
          return;
        })
        .catch((error) => {
          LOG_ERROR('Error: useBookInstantConsultation>>bookInstantConsultation', error);
        });
    },
    [userData, analytics],
  );

  return bookInstantConsultation;
};

export default useBookInstantConsultation;
