export enum FileType {
  LAB_TEST = 'lab_test',
  PATIENT_HISTORY = 'patient_history',
  STI_IMAGES = 'sti_images',
}

export enum FILE_ACCEPT_TYPE {
  IMAGE = '.png, .jpg, .jpeg',
  PDF = 'application/pdf',
}
