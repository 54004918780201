import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

import { IProviderData } from '@Reducers/provider';
import { IClinicLocationsData } from '@Reducers/schedule';
import { useAppSelector } from '@Redux/hooks';

import ClinicGallery from './clinic-gallery';
import { ColorType, FontType } from './common-exports';
import DoctorImage from './core/doctor-image/v1';
import Text from './design-system/text';

const ClinicDetails = ({
  clinic,
  isDummyClinic = false,
}: {
  clinic: IClinicLocationsData;
  isDummyClinic?: boolean;
}) => {
  const provider = useAppSelector((state) => state.provider);
  const allDoctorData: Record<string, IProviderData> = provider?.allProviderData;
  const doctorList = Object.values(allDoctorData)
    .filter((doctor) => !!doctor.image && doctor?.image !== '/assets/svgs/avatar.svg')
    .slice(0, 5);
  return (
    <section id='clinic-details' className='flex justify-between items-start gap-6'>
      <div className='flex flex-col'>
        <Text font={FontType.SEMIBOLD_16_150} color={ColorType.GREYS_900}>
          Allo Health {clinic?.locality && `${clinic?.locality}`}
        </Text>
        {clinic?.rating && (
          <div className='flex items-center gap-1'>
            <Image src='/assets/google.svg' width={12} height={12} alt='google' />
            <Text className={'pl-1'} font={FontType.SEMIBOLD_10_170} color={ColorType.COOL_GREY_800}>
              {clinic?.rating}
              {!isDummyClinic && '/5'}
            </Text>
            &bull;
            <Text
              className={clsx(clinic?.totalRating ? 'block' : 'hidden')}
              font={FontType.REGULAR_10_170}
              color={ColorType.GREYS_900}>
              Ratings ({clinic?.totalRating}+)
            </Text>
          </div>
        )}
        <div className='flex items-center gap-1'>
          <Text
            className={clsx(clinic?.distance ? 'block' : 'hidden')}
            font={FontType.SEMIBOLD_10_170}
            color={ColorType.PRIMARY_600}>
            {clinic?.distance?.toFixed(2)} Kms away &bull;
          </Text>

          <Text
            className={clsx(clinic?.workingHours ? 'block' : 'hidden')}
            font={FontType.REGULAR_10_170}
            color={ColorType.GREYS_900}>
            {!clinic?.distance && 'Timings:'} {clinic?.workingHours}
          </Text>
        </div>
        {isDummyClinic && (
          <div className='flex pt-3'>
            {doctorList?.map((doctor: IProviderData, idx) => (
              <div key={doctor?.name} className={`${idx > 0 ? '-ml-1' : ''}`}>
                <DoctorImage doctorImage={doctor?.image} size={32} backgroundColor={ColorType.BLUE_GREY_100} />
              </div>
            ))}
          </div>
        )}
      </div>
      {clinic?.imageLinks?.length > 0 && (
        <div className='relative'>
          <ClinicGallery images={clinic?.imageLinks} />
        </div>
      )}
    </section>
  );
};

export default ClinicDetails;
