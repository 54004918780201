import { UpdateQueryDataPayload } from '@Services/Common/common-payload';
import { useCallback } from 'react';
import * as NProgress from 'nprogress';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { Routes } from './routes';

interface IRouterParams {
  route: Routes;
  subRoute?: string;
  query?: UpdateQueryDataPayload;
  options?: NavigateOptions;
}

function useAnasRouter() {
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    requestAnimationFrame(() => NProgress.done());
  }, [pathname]);

  const replace = useCallback(
    (payload: IRouterParams) => {
      if (payload.route !== pathname) requestAnimationFrame(() => NProgress.start());

      let url = `/${payload.route}${payload.subRoute ? `/${payload.subRoute}` : ''}`;

      if (payload.query) {
        const queryString = Object.entries(payload.query)
          .filter(([_, value]) => Boolean(value))
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
          .join('&');

        if (queryString) url += `?${queryString}`;
      }

      router.replace(url, payload.options);
    },
    [router, pathname],
  );

  const push = useCallback(
    (payload: IRouterParams) => {
      if (payload.route !== pathname) requestAnimationFrame(() => NProgress.start());

      let url = `/${payload.route}${payload.subRoute ? `/${payload.subRoute}` : ''}`;

      if (payload.query) {
        const queryString = Object.entries(payload.query)
          .filter(([_, value]) => Boolean(value))
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
          .join('&');

        if (queryString) url += `?${queryString}`;
      }

      router.push(url, payload.options);
    },
    [router, pathname, history],
  );

  return { ...router, push, replace };
}

export default useAnasRouter;
