import dayjs from 'dayjs';
import Image from 'next/image';
import { useMemo } from 'react';

import { ColorType, FontType } from '@Components/common-exports';
import SelectionCardHeader from '@Components/core/selection-card-header';
import { Button } from '@Components/design-system/button/button';
import Text from '@Components/design-system/text';
import DoctorCardV1 from '@Components/doctor-cards/version-1';
import OnlineMode from '@Images/svgs/consultation-online.svg';
import { IQueryData } from '@Reducers/common';
import { IProviderData } from '@Reducers/provider';
import { ISlot } from '@Reducers/schedule';
import { useAppSelector } from '@Redux/hooks';
import { DEFAULT_LOCATION_CODE } from '@Utils/constants';

const Online = ({
  changeLocation,
  onlineInstantPreviewSlots,
  isSelected,
}: {
  changeLocation: (locationCode: string) => void;
  onlineInstantPreviewSlots: ISlot[];
  isSelected: boolean;
}) => {
  const queryData: IQueryData = useAppSelector((state) => state.common.queryData);
  const allProviderData: Record<string, IProviderData> = useAppSelector((state) => state.provider.allProviderData);
  const slotsToDisplay = useMemo(() => onlineInstantPreviewSlots?.slice(0, 4), [onlineInstantPreviewSlots]);
  const provider = useMemo(() => {
    if (queryData?.providerId?.[0]) return allProviderData[queryData?.providerId[0]];
  }, [queryData?.providerId, allProviderData]);

  return (
    <section>
      <SelectionCardHeader
        id='consult-online-option'
        isSelected={true}
        imageSrc={OnlineMode}
        headerClass='p-4 border-b border-GREYS_400'
        title={
          <div>
            <Text color={ColorType.GREYS_900} font={FontType.SEMIBOLD_14_150}>
              Consult Online
            </Text>
            <Text color={ColorType.COOL_GREY_700} font={FontType.REGULAR_12_170}>
              Talk to Doctor in the next available hour
            </Text>
          </div>
        }
        footer={
          <div>
            <div className='bg-PRIMARY_25 py-2 grid grid-cols-2 px-4 items-center'>
              <Text color={ColorType.INDIGO_600} font={FontType.SEMIBOLD_12_170}></Text>

              <Button
                label={
                  <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_100}>
                    Book Online
                  </Text>
                }
                fullWidth={true}
                className={`cursor-pointer`}
                id='book-online'
                onPress={(e) => {
                  e.stopPropagation();
                  changeLocation(DEFAULT_LOCATION_CODE);
                }}
                type='secondary'
                borderRadius={50}
                size='large'
                align='center'
              />
            </div>
          </div>
        }
        handleSelection={(e) => {
          e.stopPropagation();
          changeLocation(DEFAULT_LOCATION_CODE);
        }}>
        {onlineInstantPreviewSlots?.length > 0 && (
          <>
            <div className='absolute top-0 right-4 flex items-center gap-1 bg-ORANGE_YELLOW_200 w-24 py-1 justify-center rounded-b-lg '>
              <Image src={'/assets/svgs/instant.svg'} height={18} width={18} alt='instant' />
              <Text font={FontType.SEMIBOLD_8_170} color={ColorType.GREYS_900} className='z-10'>
                INSTANT SLOTS
              </Text>
            </div>
            <Text className='px-4 pt-4 flex gap-2' font={FontType.REGULAR_12_170} color={ColorType.SUCCESS_700}>
              <Image src={'/assets/svgs/clock-success.svg'} height={16} width={16} alt='allo-icon' />
              slots available for today
            </Text>
            <div className='px-4 pt-5 flex overflow-x-scroll gap-4'>
              {slotsToDisplay?.map((slot) => (
                <button
                  key={slot?.startDate}
                  className={
                    `${'bg-GREYS_100 border-[1.5px] border-GREYS_400'}` +
                    ' cursor-pointer px-1 py-1.5 flex justify-center rounded-lg relative'
                  }>
                  <div className='rounded border border-white absolute -top-3 -right-3 bg-ORANGE_YELLOW_100 size-6 flex items-center justify-center'>
                    <Image src={`/assets/svgs/instant.svg`} width={20} height={20} alt='instant slot' layout='fixed' />
                  </div>
                  <Text className='whitespace-nowrap' font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_800}>
                    {dayjs(slot?.startDate)?.format('h:mm A')}
                  </Text>
                </button>
              ))}
            </div>
          </>
        )}
        <div className='px-4 py-3'>
          <DoctorCardV1
            doctorImage={provider?.image || '/assets/i-sandip.png'}
            title={provider?.id ? `Will be meeting ${provider?.name}` : 'A specialised doctor will be assigned'}
            description={
              provider?.id
                ? `${provider?.designation} | ${provider?.experience}`
                : 'Allo doctors specialise in giving best care to you.'
            }
          />
        </div>
      </SelectionCardHeader>
    </section>
  );
};

export default Online;
