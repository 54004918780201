import dayjs from 'dayjs';

import { ActionConsts } from '@Definitions/ActionConsts';

import { Reducer } from './types';

const INITIAL_STATE: Reducer = {
  instantPreviewSlots: {
    slots: [],
  },
  actualSlots: {
    slots: {},
  },
  redcliffeSlots: {
    slots: {},
  },
};

export const SlotReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Slot.GetPreviewSlotsInit:
      return {
        ...state,
        instantPreviewSlots: {
          slots: [],
          isLoading: true,
        },
      };
    case ActionConsts.Slot.GetPreviewSlotsSuccess:
      return {
        ...state,
        instantPreviewSlots: {
          slots: action.payload,
          time: dayjs().toISOString(),
          isLoading: false,
        },
      };
    case ActionConsts.Slot.GetPreviewSlotsFail:
      return {
        ...state,
        instantPreviewSlots: {
          slots: [],
          isLoading: false,
        },
      };
    case ActionConsts.Slot.ResetPreviewSlots:
      return {
        ...state,
        instantPreviewSlots: INITIAL_STATE.instantPreviewSlots,
      };
    case ActionConsts.Slot.GetSlotsInit:
      return {
        ...state,
        actualSlots: {
          slots: {},
          isLoading: true,
        },
      };
    case ActionConsts.Slot.GetSlotsSuccess:
      return {
        ...state,
        actualSlots: {
          slots: action.slotsData,
          isLoading: false,
        },
      };
    case ActionConsts.Slot.GetSlotsFail:
      return {
        ...state,
        actualSlots: {
          slots: {},
          isLoading: false,
        },
      };
    case ActionConsts.Slot.ResetSlots:
      return {
        ...state,
        actualSlots: INITIAL_STATE.actualSlots,
      };
    case ActionConsts.Slot.GetRedcliffeSlotsInit:
      return {
        ...state,
        redcliffeSlots: {
          slots: {},
          isLoading: true,
        },
      };
    case ActionConsts.Slot.GetRedcliffeSlotsSuccess:
      return {
        ...state,
        redcliffeSlots: {
          slots: action.redcliffeSlots,
          isLoading: false,
        },
      };
    case ActionConsts.Slot.GetRedcliffeSlotsFail:
      return {
        ...state,
        redcliffeSlots: {
          slots: {},
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default SlotReducer;
