import { ActionConsts } from '@Definitions/index';

const INITIAL_STATE = {
  resources: [],
  isLoading: false,
};

export const ResourceReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Resource.GetResourcesInit:
      return {
        ...state,
        resources: INITIAL_STATE.resources,
        isLoading: true,
      };
    case ActionConsts.Resource.GetResourcesSuccess:
      return {
        ...state,
        resources: action.resources,
        isLoading: false,
      };
    case ActionConsts.Resource.GetResourcesFail:
      return {
        ...state,
        resources: INITIAL_STATE.resources,
        isLoading: false,
      };
    case ActionConsts.Resource.ResetResources:
      return INITIAL_STATE;
    default:
      return state;
  }
};
