export enum Routes {
  DASHBOARD = 'dashboard',
  FEEDBACK = 'feedback',
  APPOINTMENTS = 'appointments',
  APPOINTMENT = 'appointment',
  SCHEDULE = 'schedule',
  CONFIRMATION = 'confirmation',
  ASSESSMENT_REPORT = 'assessment-report',
  ASSESSMENT = 'assessment',
  LATEST_RECORD = 'records/latest',
  RECORDS = 'records',
  CX_JOURNEY = 'cx-journey',
  PURCHASE = 'purchase',
  LOGIN = 'login',
  LOGOUT = 'logout',
  PHMS = 'provider-meet',
  HMS = 'meet',
  REDIRECT = 'redirect',
  RESOURCES = 'resources',
  AUTHORIZE = 'authorize',
  PREPORT = 'prescription-report',
  REPORT = 'report',
  PRINT_PRESCRIPTION = 'print-prescription',
  ACTIVITY = 'profile/activity',
  PROFILE = 'profile',
  MEDICINES = 'medicines',
  LAB_TESTS = 'lab-tests',
  DASHBOARDV2 = 'dashboard-v2',
}
