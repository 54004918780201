import { ActionConsts } from '@Definitions/index';

export interface IProviderData {
  id?: string;
  name?: string;
  designation?: string;
  linkName?: string;
  image?: string;
  signature?: string;
  experience?: string;
  certification?: string;
  gender?: string;
  display?: boolean;
  regno?: string;
  email?: string;
  preferredLanguages?: string[];
  consultationTypeConfig?: any;
  patientsTreated?: number;
  rating?: number;
  isPhysician?: boolean;
  isTherapist?: boolean;
}

export interface ILoading {
  isLoading?: boolean;
}

export interface IAssigedProviderLocations extends ILoading {
  providerId?: string;
  locationIds: string[];
}

export interface IProvider {
  providerData?: IProviderData;
  therapistData?: IProviderData;
  allProviderData?: any[];
  assignedProviderLocations: IAssigedProviderLocations;
  isLoading: boolean;
}

export interface IGetOfflineAvailabilityPayload {
  id: string;
  typeId: string;
  startTime: Date;
  endTime: Date;
}

const INITIAL_STATE: IProvider = {
  providerData: {
    name: 'Doctor',
    designation: 'MBBS, MD (Psychiatry)',
    certification: '',
    signature: '/assets/svgs/s-default.svg',
    image: '/assets/svgs/avatar.svg',
    linkName: '',
    experience: '4 years of experience',
    display: false,
    regno: '',
    email: 'hello@allohealth.care',
  },
  therapistData: {
    name: 'Doctor',
    designation: 'Msc. Psychiatry',
    certification: '',
    signature: '/assets/svgs/s-default.svg',
    image: '/assets/svgs/avatar.svg',
    linkName: '',
    experience: '4 years of experience',
    display: false,
    regno: '',
    email: 'hello@allohealth.care',
  },
  allProviderData: [],
  assignedProviderLocations: {
    locationIds: [],
  },
  isLoading: false,
};

export const ProviderReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Provider.GetProviderDataInit:
      return {
        ...state,
        providerData: INITIAL_STATE.providerData,
        isLoading: true,
      };
    case ActionConsts.Provider.GetProviderDataSuccess:
      return {
        ...state,
        providerData: action.providerData,
        isLoading: false,
      };
    case ActionConsts.Provider.GetProviderDataFail:
      return {
        ...state,
        providerData: INITIAL_STATE.providerData,
        isLoading: false,
      };
    case ActionConsts.Provider.GetAllProviderDataInit:
      return {
        ...state,
        allProviderData: INITIAL_STATE.allProviderData,
        isLoading: true,
      };
    case ActionConsts.Provider.GetAllProviderDataSuccess:
      return {
        ...state,
        allProviderData: action.allProviderData,
        isLoading: false,
      };
    case ActionConsts.Provider.GetAllProviderDataFail:
      return {
        ...state,
        allProviderData: INITIAL_STATE.allProviderData,
        isLoading: false,
      };
    case ActionConsts.Provider.GetTherapistDataInit:
      return {
        ...state,
        therapistData: INITIAL_STATE.therapistData,
        isLoading: true,
      };
    case ActionConsts.Provider.GetTherapistDataSuccess:
      return {
        ...state,
        therapistData: action.therapistData,
        isLoading: false,
      };
    case ActionConsts.Provider.GetTherapistDataFail:
      return {
        ...state,
        therapistData: INITIAL_STATE.therapistData,
        isLoading: false,
      };
    case ActionConsts.Provider.GetOfflineAvailabilityInit:
      return {
        ...state,
        assignedProviderLocations: {
          isLoading: true,
          locationIds: [],
        },
      };
    case ActionConsts.Provider.GetOfflineAvailabilitySuccess:
      return {
        ...state,
        assignedProviderLocations: {
          isLoading: false,
          providerId: action.providerId,
          locationIds: action?.locationIds ?? [],
        },
      };
    case ActionConsts.Provider.GetOfflineAvailabilityFail:
      return {
        ...state,
        assignedProviderLocations: {
          isLoading: false,
          locationIds: [],
        },
      };
    case ActionConsts.Provider.GetOfflineAvailabilityReset:
      return {
        ...state,
        assignedProviderLocations: INITIAL_STATE.assignedProviderLocations,
      };
    case ActionConsts.Provider.ResetProviderData:
      return {
        ...state,
        providerData: INITIAL_STATE.providerData,
        therapistData: INITIAL_STATE.therapistData,
        allProviderData: INITIAL_STATE.allProviderData,
        isLoading: false,
      };
    default:
      return state;
  }
};
