import Image from 'next/image';

import ClinicDetails from '@Components/clinic-details';
import { ColorType, FontType } from '@Components/common-exports';
import SelectionCardHeader from '@Components/core/selection-card-header';
import { Button } from '@Components/design-system/button/button';
import Text from '@Components/design-system/text';
import OfflineMode from '@Images/svgs/consultation-clinic.svg';
import { DUMMY_CLINIC } from '@Utils/constants';

const NoLocationGiven = ({ browseClinics, isSelected }: { browseClinics: () => void; isSelected: boolean }) => {
  return (
    <section>
      <SelectionCardHeader
        isSelected={isSelected}
        imageSrc={OfflineMode}
        headerClass='p-4'
        title={
          <div>
            <Text color={ColorType.GREYS_900} font={FontType.SEMIBOLD_14_150}>
              Visit Nearest Clinic
            </Text>
            <Text color={ColorType.SUCCESS_700} font={FontType.REGULAR_11_170}>
              Browse to see availability
            </Text>
          </div>
        }
        handleSelection={(e) => {
          e.stopPropagation();
          browseClinics();
        }}>
        <div className='absolute top-0 right-4 flex gap-1 bg-PRIMARY_600 w-24 py-1 justify-center rounded-b-lg '>
          <Image src={'/assets/svgs/star-grey.svg'} height={12} width={12} alt='allo icon' />
          <Text font={FontType.SEMIBOLD_8_170} color={ColorType.GREYS_100} className='z-10'>
            PAY AT CLINIC
          </Text>
        </div>
        <div className='px-4'>
          <ClinicDetails clinic={DUMMY_CLINIC} isDummyClinic />
        </div>
        <div className='px-4 py-3'>
          <Text font={FontType.SEMIBOLD_16_150} color={ColorType.GREYS_900}>
            Find a clinic near you!
          </Text>
          <Text className='mb-4' font={FontType.REGULAR_12_170} color={ColorType.GREYS_700}>
            We might be near you, talk to an expert in person. <br /> Find Allo Health Clinic in your city.
          </Text>
          <Button
            label={
              <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_100}>
                Browse clinics near you &gt;
              </Text>
            }
            fullWidth={true}
            id='browse-clinics'
            className={`cursor-pointer`}
            onPress={browseClinics}
            type='secondary'
            borderRadius={50}
            size='large'
            align='center'
          />
        </div>
      </SelectionCardHeader>
    </section>
  );
};

export default NoLocationGiven;
