import { getProvidersForLocation } from '@Components/consultation-options';
import { ILocationProvidersMap } from '@Reducers/location';
import { IProviderData } from '@Reducers/provider';
import { IClinicLocationsData } from '@Reducers/schedule';
import { ClinicFilters } from '@Utils/constants';

export const filterClinicLocations = (
  clinics: Record<string, IClinicLocationsData[]>,
  allowedClinicIds: string[],
): Record<string, IClinicLocationsData[]> => {
  const filterClinicLocations = {};
  for (const [city, cityClinics] of Object.entries(clinics ?? {})) {
    for (const clinic of cityClinics) {
      if (allowedClinicIds.includes(clinic?.id)) {
        if (!filterClinicLocations?.[city]) filterClinicLocations[city] = [];
        filterClinicLocations?.[city]?.push(clinic);
      }
    }
  }
  return filterClinicLocations;
};

export const arrangeOfflineClinicsByCity = (clinics) => {
  const cityObj = {};
  for (const clinic of clinics ?? []) {
    if (clinic?.type !== 'offline' || clinic.code === 'PRACTO') continue;
    const city = clinic?.city;
    if (!cityObj[city]) cityObj[city] = [];
    cityObj[city]?.push({
      id: clinic?.id,
      address: clinic?.address,
      city: clinic?.city,
      locality: clinic?.locality,
      code: clinic?.code,
      mapUrl: clinic?.mapUrl,
      name: clinic?.name,
      imageLinks: (clinic?.imageLinks || []).map((image: string) => image?.trim()),
      workingHours: clinic?.working_hours ?? '',
      latitude: parseFloat(clinic?.latitude),
      longitude: parseFloat(clinic?.longitude),
      totalRating: clinic?.total_ratings,
      rating: clinic?.rating,
    });
  }
  return cityObj;
};

export const getClinicCodeToCityMap = (clinics) => {
  const clinicCodeToCityMap = {};
  for (const clinic of clinics || []) {
    clinicCodeToCityMap[clinic?.code] = clinic?.city;
  }
  return clinicCodeToCityMap;
};

export type Coordinate = {
  latitude: number;
  longitude: number;
};

const haversineDistance = (coord1: Coordinate, coord2: Coordinate): number => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(coord2.latitude - coord1.latitude);
  const dLon = toRadians(coord2.longitude - coord1.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(coord1.latitude)) *
      Math.cos(toRadians(coord2.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const toRadians = (value: number): number => {
  return (value * Math.PI) / 180;
};

export const sortClinicsByDistance = (
  clinics: Record<string, IClinicLocationsData[]>,
  reference: Coordinate,
): Record<string, IClinicLocationsData[]> => {
  const sortedClinics = {};
  const clinicEntries = Object.entries(clinics || {});
  for (const [city, cityClinics] of clinicEntries) {
    sortedClinics[city] = cityClinics
      ?.map((clinic) => {
        const clinicCoordinate = {
          latitude: clinic?.latitude,
          longitude: clinic?.longitude,
        };
        return {
          ...clinic,
          distance: haversineDistance(reference, clinicCoordinate),
        };
      })
      ?.sort((a, b) => a?.distance - b?.distance);
  }
  return sortedClinics;
};

export const sortCitiesByClinicDistance = (clinics: Record<string, IClinicLocationsData[]>) => {
  const sortedCities = Object.keys(clinics || {}).sort((cityA, cityB) => {
    const cityAClinic = clinics[cityA]?.[0];
    const cityBClinic = clinics[cityB]?.[0];
    return cityAClinic?.distance - cityBClinic?.distance;
  });
  return sortedCities;
};

export const getUserCityByClinicDistance = (clinics: Record<string, IClinicLocationsData[]>) => {
  let closestClinic = null;
  //flatten the object
  const clinicArray = Object.values(clinics || {})?.reduce((acc, val) => acc.concat(val), []);
  for (const clinic of clinicArray) {
    if (clinic?.distance && (!closestClinic || clinic.distance < closestClinic.distance)) {
      closestClinic = clinic;
    }
  }
  return {
    city: closestClinic?.city,
    clinicCode: closestClinic?.code,
  };
};

export const getAvailableFilters = (payload: {
  locations: IClinicLocationsData[];
  allProviderData: Record<string, IProviderData>;
  locationProviders: ILocationProvidersMap;
}): ClinicFilters[] => {
  const { locations, allProviderData, locationProviders } = payload;
  const availableFilters: ClinicFilters[] = [ClinicFilters.All];
  (locations || []).forEach((clinic) => {
    const providers = getProvidersForLocation(clinic?.id, allProviderData, locationProviders) || [];
    if (providers.some((provider) => provider.gender === 'M')) {
      availableFilters.push(ClinicFilters['Male Doctors']);
    }
    if (providers.some((provider) => provider.gender === 'F')) {
      availableFilters.push(ClinicFilters['Female Doctors']);
    }
    if (clinic.rating && Number.parseInt(clinic.rating as string) >= 4) {
      availableFilters.push(ClinicFilters['Highly Rated']);
    }
    if (providers.some((provider) => provider.preferredLanguages.includes('English'))) {
      availableFilters.push(ClinicFilters['English Speaking']);
    }
    if (clinic.distance && clinic.distance <= 5) {
      availableFilters.push(ClinicFilters['Nearest Doctors']);
    }
  });
  return Array.from(new Set(availableFilters));
};

export const findClinicByCode = (locationCode: string, clinicLocations: Record<string, IClinicLocationsData[]>) => {
  return Object.entries(clinicLocations)
    .find(([city, clinics]) => {
      return clinics.find((clinic) => clinic.code === locationCode);
    })?.[1]
    ?.find((clinic) => clinic.code === locationCode);
};

export const findClinicById = (locationId: string, clinicLocations: Record<string, IClinicLocationsData[]>) => {
  return Object.entries(clinicLocations)
    .find(([city, clinics]) => {
      return clinics.find((clinic) => clinic.id === locationId);
    })?.[1]
    ?.find((clinic) => clinic.id === locationId);
};
