import React from 'react';

import { ColorType, FontType } from '@Components/common-exports';
import DoctorImage from '@Components/core/doctor-image/v1';
import Text from '@Components/design-system/text';

const DoctorCardV1 = ({
  doctorImage,
  title,
  description,
}: {
  doctorImage: string;
  title: string;
  description: string;
}) => {
  return (
    <div className='py-2 px-3 flex gap-3 bg-GREYS_300 rounded-lg overflow-hidden'>
      <div className='w-10 h-10'>
        <DoctorImage
          doctorImage={doctorImage}
          size={40}
          backgroundShape='square'
          backgroundColor={ColorType.BLUE_GREY_600}
        />
      </div>
      <div className='flex flex-col'>
        <Text font={FontType.SEMIBOLD_12_170} color={ColorType.GREYS_900}>
          {title}
        </Text>
        {description && (
          <Text className='text-ellipsis' font={FontType.REGULAR_10_170} color={ColorType.GREYS_800}>
            {description.slice(0, 50)} {description.length > 50 && '...'}
          </Text>
        )}
      </div>
    </div>
  );
};

export default DoctorCardV1;
