import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { TEST_STATES, THERAPY_STATES } from '@Components/design-system/message-card';
import { ActionConsts } from '@Definitions/index';
import { Fulfilment, IDrugOrder, ILabTestOrder, IPatientInvoice } from '@Reducers/order';
import { OrderService } from '@Services/index';
import { ERROR_MESSAGES, CONSULTATION_TYPE, ItemType } from '@Utils/constants';
import { combinePaidInvoices } from '@Utils/Helpers/invoice.helper';
import {
  InvoiceStatus,
  formatOrderData,
  getStatus,
  getValidSortedInvoices,
  sortCounsellingOrders,
  sortFollowUpOrdersOnCreatedAt,
} from '@Utils/Helpers/Order/helper';
import { formatResources } from '@Utils/Helpers/resource.helper';
import { LOG_ERROR } from '@Utils/logger';

export const OrderActions = {
  UpdateOrderConfirmed: (orderConfirmed: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      orderConfirmed,
      type: ActionConsts.Order.UpdateOrderConfirmed,
    });
  },
  GetDrugOrders:
    (requestPayload: { encounterId: string; ipxId?: string; paidDate?: string }) => async (dispatch: Dispatch) => {
      try {
        dispatch({ type: ActionConsts.Order.GetDrugOrdersInit });
        const { paidDate, ...payload } = requestPayload;
        const result = await OrderService.GetInvoiceForEncounter(payload);
        if (result?.status === 200 && result?.data?.length == 0) {
          dispatch({
            drugOrders: [],
            type: ActionConsts.Order.GetDrugOrdersSuccess,
          });
        } else if (result?.status === 200 && result?.data?.length > 0) {
          const drugOrders: IDrugOrder[] = result?.data
            ?.filter((drugOrder) => drugOrder?.deliveryDetails?.status !== 'pending')
            ?.map((drugOrder) => {
              return {
                invoiceId: drugOrder?.invoiceId,
                status: getStatus(drugOrder?.deliveryDetails, drugOrder?.vendor),
                shippingOrderId: drugOrder?.trackingId,
                vendor: drugOrder?.vendor,
                address: drugOrder?.deliveryDetails?.to_address,
                pincode: drugOrder?.deliveryDetails?.to_pincode,
                trackingUrl: drugOrder?.deliveryDetails?.tracking_url,
                isCod: drugOrder?.deliveryDetails?.is_cod,
                estimatedDate: drugOrder?.deliveryDetails?.estimated_delivery_time ?? '',
                timeStamps: {
                  received: paidDate ?? '',
                  packed: drugOrder?.invoice?.createdAt as string,
                  dispatched:
                    drugOrder?.dispatchDetails?.status === 'dispatched'
                      ? (drugOrder?.dispatchDetails?.time as string)
                      : '',
                  delivered: drugOrder?.deliveryDetails?.delivery_time ?? '',
                },
                drugs:
                  drugOrder?.items?.map((item) => {
                    return {
                      id: item?.drugId,
                      name: item?.drugName,
                      quantity: item?.dispatchQuantity,
                    };
                  }) ?? [],
              } as IDrugOrder;
            });
          dispatch({
            drugOrders: drugOrders,
            type: ActionConsts.Order.GetDrugOrdersSuccess,
          });
        } else {
          dispatch({
            type: ActionConsts.Order.GetDrugOrdersFail,
          });
        }
      } catch (error) {
        const referenceData = {
          error: error,
          request: requestPayload,
        };
        LOG_ERROR('Error: OrderActions>>GetInvoice', referenceData);
        dispatch({
          type: ActionConsts.Order.GetDrugOrdersFail,
        });
      }
    },
  GetPatientInvoices:
    (requestPayload: { statuses: InvoiceStatus[]; ipxId?: string; encounterId?: string; retryCount?: number }) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch({ type: ActionConsts.Order.GetPatientInvoicesInit });
        const { retryCount = 0, ...payload } = requestPayload;
        let fetchCount = retryCount;
        const fetchInvoiceData = async () => {
          const result = await OrderService.GetPatientInvoices(payload);
          if (result?.status === 200 && result?.data?.length > 0) {
            const latestInvoice = getValidSortedInvoices(result?.data)?.[0] ?? {};
            const latestInvoiceEncounterId = latestInvoice?.encounterId;
            const paidInvoicesForEncounter = combinePaidInvoices(
              result?.data?.filter((invoice) => invoice?.encounterId === latestInvoiceEncounterId),
            );
            dispatch({
              patientInvoice: {
                id: paidInvoicesForEncounter?.id ?? '',
                status: paidInvoicesForEncounter?.status ?? '',
                createdAt: latestInvoice?.createdAt ?? '',
                encounterId: paidInvoicesForEncounter?.encounterId ?? '',
                items:
                  paidInvoicesForEncounter?.items?.map((invoiceItem) => {
                    return {
                      type: invoiceItem?.type,
                      quantity: invoiceItem?.quantity,
                      typeId: invoiceItem?.typeId,
                    };
                  }) ?? [],
              } as IPatientInvoice,
              type: ActionConsts.Order.GetPatientInvoicesSuccess,
            });
          } else if (fetchCount > 0) {
            //invoice not found, retry
            setTimeout(() => {
              fetchCount--;
              fetchInvoiceData().catch((error) => catchError(error));
            }, 10_000);
          } else {
            dispatch({ type: ActionConsts.Order.GetPatientInvoicesFail });
          }
        };

        const catchError = (error) => {
          const referenceData = {
            error: error,
          };
          LOG_ERROR('Error: OrderActions>>GetPatientInvoices>>PromiseFailed', referenceData);
          dispatch({
            message: ERROR_MESSAGES.get,
            type: ActionConsts.Order.GetPatientInvoicesFail,
          });
        };
        await fetchInvoiceData().catch((error) => catchError(error));
      } catch (error) {
        const referenceData = {
          error: error,
          request: requestPayload,
        };
        LOG_ERROR('Error: OrderActions>>GetPatientInvoices', referenceData);
        dispatch({ type: ActionConsts.Order.GetPatientInvoicesFail });
      }
    },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  GetConsultationOrders:
    (requestPayload: { paidDate?: string; ipxId?: string; followUpDueDate?: string; areAllDrugsDelivered?: boolean }) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch({ type: ActionConsts.Order.GetConsultationOrdersInit });
        const result = await OrderService.GetConsultationOrders(requestPayload?.ipxId);
        if (result?.status === 200 && result?.data?.data?.length === 0) {
          dispatch({
            consultationOrders: result?.data?.data,
            type: ActionConsts.Order.GetConsultationOrdersSuccess,
          });
        } else if (result?.status === 200 && result?.data?.data?.length > 0) {
          let counsellingOrders = result?.data?.data?.filter(
            (consultation) => consultation?.consultationType?.code === CONSULTATION_TYPE.TH,
          );
          counsellingOrders = sortCounsellingOrders(counsellingOrders);

          const followupOrders = result?.data?.data?.filter(
            (consultation) => consultation?.consultationType?.code === CONSULTATION_TYPE.FU,
          );
          let patientQueryOrders = result?.data?.data?.filter(
            (consultation) => consultation?.consultationType?.code === CONSULTATION_TYPE.PQ,
          );
          let reportReadingOrders = result?.data?.data?.filter(
            (consultation) => consultation?.consultationType?.code === CONSULTATION_TYPE.RR,
          );
          let screeningCallOrders = result?.data?.data?.filter(
            (consultation) => consultation?.consultationType?.code === CONSULTATION_TYPE.SC,
          );
          patientQueryOrders = sortCounsellingOrders(patientQueryOrders);
          reportReadingOrders = sortCounsellingOrders(reportReadingOrders);
          screeningCallOrders = sortFollowUpOrdersOnCreatedAt(screeningCallOrders);
          const formattedFollowupOrders = formatOrderData(
            sortFollowUpOrdersOnCreatedAt(followupOrders),
            requestPayload?.areAllDrugsDelivered
              ? dayjs(requestPayload?.followUpDueDate).subtract(7, 'day')
              : undefined,
          );
          const filteredFollowupOrders = formattedFollowupOrders?.filter(
            (order) => order?.status !== THERAPY_STATES.CANCELLED,
          );
          dispatch({
            consultationOrders: {
              counsellingOrders: formatOrderData(counsellingOrders, requestPayload?.paidDate),
              followupOrders: filteredFollowupOrders,
              patientQueryOrders: formatOrderData(patientQueryOrders, new Date().toISOString()),
              reportReadingOrders: formatOrderData(reportReadingOrders, new Date().toISOString()),
              screeningCallOrders: formatOrderData(screeningCallOrders, new Date().toISOString()),
            },
            type: ActionConsts.Order.GetConsultationOrdersSuccess,
          });
        } else {
          dispatch({ type: ActionConsts.Order.GetConsultationOrdersFail });
        }
      } catch (error) {
        LOG_ERROR('Error: OrderActions>>GetConsultationOrders', error);
        dispatch({ type: ActionConsts.Order.GetConsultationOrdersFail });
      }
    },
  GetLabtestOrders:
    (payload: { encounterId: string; prescribedAt: string; ipxId?: string }) => async (dispatch: Dispatch) => {
      try {
        dispatch({ type: ActionConsts.Order.GetLabTestOrdersInit });
        const result = await OrderService.GetLabTestOrders(payload?.ipxId);
        if (result?.status === 200 && result?.data?.length === 0) {
          dispatch({
            labTestOrders: [],
            type: ActionConsts.Order.GetLabTestOrdersSuccess,
          });
        } else if (result?.status === 200 && result?.data?.length > 0) {
          const matchingOrders: ILabTestOrder[] = [];
          for (const order of result?.data || []) {
            if (order?.fulfillment?.encounterId === payload.encounterId) {
              let status;
              switch (order?.fulfillment?.status) {
                case Fulfilment.COLLECTED:
                case Fulfilment.SAMPLE_SYNCED:
                case Fulfilment.DELAYED:
                  status = TEST_STATES.SAMPLE_COLLECTED;
                  break;
                case Fulfilment.REPORT_SHARED:
                  status = TEST_STATES.REPORT_GENERATED;
                  break;
                case Fulfilment.SCHEDULED:
                  status = TEST_STATES.ON_THE_WAY;
                  break;
                case Fulfilment.CANCELLED:
                  status = TEST_STATES.CANCELLED;
                  break;
                case Fulfilment.PENDING:
                  status = TEST_STATES.PENDING_COLLECTION;
                  break;
                default:
                  continue;
              }
              matchingOrders.push({
                id: order?.fulfillment?.id,
                status,
                vendor: order?.vendorDetails?.vendorName,
                vendorReferenceId: order?.fulfillment?.vendorReferenceId,
                address: order?.vendorDetails?.vendorLocation,
                labTests: order?.labItems.map((item) => {
                  return {
                    id: item?.id,
                    name: item?.name,
                    preparation: item?.preparation,
                  };
                }),
                timeStamps: {
                  prescribed: payload.prescribedAt,
                  scheduled: order?.fulfillment?.scheduledDate || order?.fulfillment?.createdAt,
                  collected: order?.fulfillment?.collectionDate,
                  reported: order?.fulfillment?.reportedDate,
                },
              });
            }
          }
          dispatch({
            labTestOrders: matchingOrders,
            type: ActionConsts.Order.GetLabTestOrdersSuccess,
          });
        } else {
          dispatch({ type: ActionConsts.Order.GetLabTestOrdersFail });
        }
      } catch (error) {
        LOG_ERROR('Error: OrderActions>>GetLabtestOrders', error);
        dispatch({ type: ActionConsts.Order.GetLabTestOrdersFail });
      }
    },
  GetPendingOrders: (payload: { ipxId?: string; showArticles?: boolean }) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Order.GetPendingOrdersInit });
      const result = await OrderService.GetPendingOrders({
        ipxId: payload?.ipxId,
        showArticles: payload?.showArticles,
      });
      if (result?.status === 200) {
        const orders = result?.data?.orders;
        const resources = result?.data?.articles;
        const drugs = orders?.filter((order) => order?.drugId);
        const labTests = orders?.filter((order) => order?.labTestId);
        const consultations = orders?.filter((order) => order?.consultationId);
        const extractPendingOrders = (orders) => {
          const encounterIds = [...new Set(orders?.map((order) => order?.encounterId))];
          const lastEncounterOrders = orders?.filter((order) => order?.encounterId === encounterIds?.[0]);
          return {
            count: orders?.length,
            encounterIds,
            lastEncounterId: encounterIds?.[0],
            lastEncounterOrders,
          };
        };
        dispatch({
          pendingOrders: {
            [ItemType.DRUG]: extractPendingOrders(drugs),
            [ItemType.LAB]: extractPendingOrders(labTests),
            [ItemType.CONSULTATION]: extractPendingOrders(consultations),
          },
          type: ActionConsts.Order.GetPendingOrdersSuccess,
        });
        dispatch({
          resources: formatResources(resources),
          type: ActionConsts.Resource.GetResourcesSuccess,
        });
      } else {
        dispatch({ type: ActionConsts.Order.GetPendingOrdersFail });
      }
    } catch (error) {
      LOG_ERROR('Error: OrderActions>>GetPendingOrders', error);
      dispatch({ type: ActionConsts.Order.GetPendingOrdersFail });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetOrderData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Order.ResetOrderData,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetConsultationData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Order.ResetConsultationData,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetInvoiceData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Order.ResetPatientInvoices,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetDrugOrders: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Order.ResetDrugOrders,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetConsultationOrders: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Order.ResetConsultationOrders,
    });
  },
};
