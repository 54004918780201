import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/ActionConsts';
import { IConsultationInvoice } from '@Reducers/billing';
import { BillingService } from '@Services/Billing';
import {
  ICreateConsultationInvoicePayload,
  ICreatePrescriptionInvoicePayload,
  IInventoryItemPurchase,
  InvoiceMode,
} from '@Services/Billing/billing-payload';
import {
  formatInvoice,
  formatInvoiceFuture,
  getInvoiceForEncounter,
  getPreviewFromOverview,
} from '@Utils/Helpers/invoice.helper';
import { LOG_ERROR } from '@Utils/logger';

export const BillingActions = {
  CreateConsultationInvoice: (requestPayload: ICreateConsultationInvoicePayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Billing.GetConsultationInvoiceInit });
      const response = await BillingService.GetConsultationInvoice({
        ...requestPayload,
        ...(requestPayload.mode === InvoiceMode.Postpaid && {
          attributes: {
            createInvoice: true,
          },
        }),
      });
      if (response?.status === 201) {
        dispatch({
          type: ActionConsts.Billing.GetConsultationInvoiceSuccess,
          invoice: response?.data as IConsultationInvoice,
        });
      } else {
        dispatch({
          error: response?.data?.message,
          status: response?.status,
          type: ActionConsts.Billing.GetConsultationInvoiceFail,
        });
      }
    } catch (error) {
      const referenceData = {
        request: requestPayload ?? {},
        error: error ?? '',
      };
      LOG_ERROR('Error: BillingAction>>CreateConsultationInvoice', referenceData);
      dispatch({
        type: ActionConsts.Billing.GetConsultationInvoiceFail,
      });
    }
  },
  CreateConsultationInvoiceFuture:
    (requestPayload: ICreateConsultationInvoicePayload, noHold = false) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch({ type: ActionConsts.Billing.GetConsultationInvoiceInit });
        const response = await (noHold
          ? BillingService.GetConsultationInvoiceWithoutHold({
              ...requestPayload,
              ...(requestPayload.mode === InvoiceMode.Postpaid && {
                attributes: {
                  createInvoice: true,
                  surchargeRate: requestPayload?.attributes?.surchargeRate,
                },
              }),
            })
          : BillingService.GetConsultationInvoice({
              ...requestPayload,
              ...(requestPayload.mode === InvoiceMode.Postpaid && {
                attributes: {
                  createInvoice: true,
                  surchargeRate: requestPayload?.attributes?.surchargeRate,
                },
              }),
            }));
        if (response?.status === 201) {
          dispatch({
            type: ActionConsts.Billing.GetConsultationInvoiceSuccess,
            invoice: formatInvoice(response?.data),
          });
        } else throw new Error(response?.data?.message);
      } catch (error) {
        const referenceData = {
          request: requestPayload ?? {},
          error: error ?? '',
        };
        LOG_ERROR('Error: BillingAction>>CreateConsultationInvoice', referenceData);
        dispatch({
          type: ActionConsts.Billing.GetConsultationInvoiceFail,
        });
        throw error;
      }
    },
  PreviewConsultationInvoice: (requestPayload: ICreateConsultationInvoicePayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Billing.PreviewConsultationInvoiceInit });
      const response = await BillingService.PreviewConsultationInvoice(requestPayload);
      if (response?.status === 201) {
        const isValidCoupon = response?.data?.amount > response?.data?.payableAmount;
        const discountAmount = response?.data?.amount - response?.data?.payableAmount;
        dispatch({
          type: ActionConsts.Billing.PreviewConsultationInvoiceSuccess,
          invoicePreview: formatInvoice(response?.data),
        });
      } else {
        dispatch({
          type: ActionConsts.Billing.PreviewConsultationInvoiceFail,
        });
      }
    } catch (error) {
      const referenceData = {
        error: error ?? '',
        requestPayload: requestPayload ?? {},
      };
      dispatch({
        type: ActionConsts.Billing.PreviewConsultationInvoiceFail,
      });
      LOG_ERROR('Error: BillingAction>>PreviewConsultationInvoice', referenceData);
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetConsultationInvoice: () => (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Billing.ResetConsultationInvoice,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetConsultationInvoicePreview: () => (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Billing.ResetConsultationInvoicePreview,
    });
  },
  PreviewPrescriptionInvoice: (requestPayload: ICreatePrescriptionInvoicePayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ActionConsts.Billing.PreviewPrescriptionInvoicesInit,
        encounterId: requestPayload.encounterId,
      });
      const { invoice } = await getInvoiceForEncounter(requestPayload.encounterId, requestPayload.voucherCode);
      if (invoice.encounterId) {
        dispatch({
          type: ActionConsts.Billing.PreviewPrescriptionInvoicesSuccess,
          invoice: invoice as IConsultationInvoice,
        });
      } else {
        dispatch({
          type: ActionConsts.Billing.PreviewPrescriptionInvoicesFail,
        });
      }
    } catch (error) {
      const referenceData = {
        error: error ?? '',
        requestPayload: requestPayload ?? {},
      };
      dispatch({
        type: ActionConsts.Billing.PreviewPrescriptionInvoicesFail,
      });
      LOG_ERROR('Error: BillingAction>>PreviewPrescriptionInvoice', referenceData);
    }
  },
  PreviewPrescriptionInvoiceFuture:
    (requestPayload: ICreatePrescriptionInvoicePayload) => async (dispatch: Dispatch) => {
      try {
        dispatch({
          type: ActionConsts.Billing.PreviewPrescriptionInvoicesInit,
        });
        const response = await BillingService.PreviewPrescriptionInvoiceFuture({
          ...requestPayload,
          attributes: {
            includePaidItems: true,
          },
          ignoreWhenNoItems: true,
        });
        if (response?.status === 201) {
          const invoice = formatInvoiceFuture(response?.data);
          if (invoice.encounterId) {
            dispatch({
              type: ActionConsts.Billing.PreviewPrescriptionInvoicesSuccess,
              invoice: invoice,
            });
          } else {
            dispatch({
              type: ActionConsts.Billing.PreviewPrescriptionInvoicesFail,
            });
          }
        } else {
          dispatch({
            type: ActionConsts.Billing.PreviewPrescriptionInvoicesFail,
          });
        }
      } catch (error) {
        const referenceData = {
          error: error ?? '',
          requestPayload: requestPayload ?? {},
        };
        dispatch({
          type: ActionConsts.Billing.PreviewPrescriptionInvoicesFail,
        });
        LOG_ERROR('Error: BillingAction>>PreviewPrescriptionInvoice', referenceData);
      }
    },
  GetInvoiceOverview: (requestPayload: ICreatePrescriptionInvoicePayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ActionConsts.Billing.PreviewPrescriptionInvoicesInit,
        encounterId: requestPayload.encounterId,
      });
      const response = await BillingService.GetInvoiceOverview({
        ...requestPayload,
        ignoreWhenNoItems: true,
      });
      if (response?.status === 201) {
        const invoice = getPreviewFromOverview(response?.data, requestPayload.encounterId);
        if (invoice.encounterId) {
          dispatch({
            type: ActionConsts.Billing.PreviewPrescriptionInvoicesSuccess,
            invoice: invoice,
          });
        } else {
          dispatch({
            type: ActionConsts.Billing.PreviewPrescriptionInvoicesFail,
          });
        }
      } else {
        dispatch({
          type: ActionConsts.Billing.PreviewPrescriptionInvoicesFail,
        });
      }
    } catch (error) {
      const referenceData = {
        error: error ?? '',
        requestPayload: requestPayload ?? {},
      };
      dispatch({
        type: ActionConsts.Billing.PreviewPrescriptionInvoicesFail,
      });
      LOG_ERROR('Error: BillingAction>>PreviewPrescriptionInvoice', referenceData);
    }
  },
  HandleItemSelection:
    (encounterId: string, typeId: string, quantity?: number) => async (dispatch: Dispatch, getState) => {
      const invoiceToModify = getState().billing.prescriptionInvoicePreviews.find(
        (invoice) => invoice.encounterId === encounterId,
      );
      if (!invoiceToModify) return;

      const updatedItems = invoiceToModify.items.map((item) => {
        if (item.typeId === typeId) {
          if (quantity) {
            return {
              ...item,
              selection: { quantity, isSelected: true },
            };
          }
          const currentSelection = item?.selection ? false : { quantity: item.quantity, isSelected: true };
          return {
            ...item,
            selection: currentSelection,
          };
        }
        return item;
      });
      try {
        dispatch({
          type: ActionConsts.Billing.HandleInvoiceItemSelection,
          encounterId,
          modifiedItems: updatedItems,
        });
      } catch (error) {
        LOG_ERROR('Error: BillingAction>>HandleItemSelection', error);
      }
    },
  CreatePrescriptionInvoice: (requestPayload: ICreatePrescriptionInvoicePayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Billing.GetPrescriptionInvoiceInit });
      const response = await BillingService.GetPrescriptionInvoice(requestPayload);
      if (response?.status === 201) {
        dispatch({
          type: ActionConsts.Billing.GetPrescriptionInvoiceSuccess,
          invoice: response?.data as IConsultationInvoice,
        });
      } else throw new Error('Axios Error');
    } catch (error) {
      const referenceData = {
        request: requestPayload ?? {},
        error: error ?? '',
      };
      if (error?.message !== 'Axios Error') LOG_ERROR('Error: BillingAction>>CreatePrescriptionInvoice', referenceData);
      dispatch({
        type: ActionConsts.Billing.GetPrescriptionInvoiceFail,
      });
      throw error;
    }
  },
  CreateInvoiceItemPurchase: (requestPayload: IInventoryItemPurchase) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Billing.CreateInventoryItemPurchaseInit });
      const response = await BillingService.GetInventoryItemPurchase(requestPayload);
      if (response?.status === 201) {
        dispatch({
          type: ActionConsts.Billing.CreateInventoryItemPurchaseSuccess,
          purchaseItem: response?.data,
        });
      } else throw new Error('Axios Error');
    } catch (error) {
      const referenceData = {
        request: requestPayload ?? {},
        error: error ?? '',
      };
      if (error?.message !== 'Axios Error') LOG_ERROR('Error: BillingAction>>CreateInvoiceItemPurchase', referenceData);
      dispatch({
        type: ActionConsts.Billing.GetPrescriptionInvoiceFail,
      });
      throw error;
    }
  },
  CreateInstantConsultationInvoice:
    (requestPayload: ICreateConsultationInvoicePayload) => async (dispatch: Dispatch) => {
      try {
        dispatch({ type: ActionConsts.Billing.GetConsultationInvoiceInit });
        const response = await BillingService.CreateInstantConsultationInvoice(requestPayload);
        if (response?.status === 201) {
          dispatch({
            type: ActionConsts.Billing.GetConsultationInvoiceSuccess,
            invoice: formatInvoice(response?.data) as IConsultationInvoice,
          });
        } else {
          dispatch({
            type: ActionConsts.Billing.GetConsultationInvoiceFail,
          });
        }
      } catch (error) {
        const referenceData = {
          request: requestPayload ?? {},
          error: error ?? '',
        };
        LOG_ERROR('Error: BillingAction>>CreateInstantConsultationInvoice', referenceData);
        dispatch({
          type: ActionConsts.Billing.GetConsultationInvoiceFail,
        });
      }
    },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetPrescriptionInvoicePreview: () => (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Billing.ResetPrescriptionInvoicePreview,
    });
  },
};
