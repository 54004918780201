import { Dispatch } from '@reduxjs/toolkit';

import createAsyncAction from '@Actions/create-async-action';
import { ActionInputs } from '@Actions/request-payloads';
import { addDays } from '@Core/dateTime/date-time';
import { ActionConsts } from '@Definitions/index';
import { IProviderData } from '@Reducers/provider';
import { RootState } from '@Redux/store';
import { ProviderService } from '@Services/Provider';
import { ERROR_MESSAGES } from '@Utils/constants';
import { generateRandomNumber, getEndDate } from '@Utils/helper';
import { LOG_ERROR } from '@Utils/logger';

export const ProviderActions = {
  GetProviderData: (providerId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Provider.GetProviderDataInit });
      const result = await ProviderService.GetProviderData(providerId);
      if (result?.status === 200) {
        dispatch({
          providerData: {
            id: result?.data?.id,
            name: result?.data?.name,
            preferredLanguages: result?.data?.preferredLanguages,
            designation: result?.data?.qualifications?.join(',') || '',
            linkName: result?.data?.profileLink,
            image: result?.data?.profileImage || '/assets/svgs/avatar.svg',
            signature: result?.data?.signature || '/assets/svgs/s-default.svg',
            experience: result?.data?.totalExperience + ' years of experience',
            certification: result?.data?.certification?.join(',') || '',
            gender: result?.data?.gender,
            display: Boolean(result?.data?.isAcceptingNewPatients && result?.data?.profileImage),
            regno: result?.data?.registrationNumber,
            email: result?.data?.email,
            consultationTypeConfig: result?.data?.consultationTypeConfig,
          } as IProviderData,
          type: ActionConsts.Provider.GetProviderDataSuccess,
        });
      } else {
        dispatch({ type: ActionConsts.Provider.GetProviderDataFail });
      }
    } catch (error) {
      const referenceData = {
        error: error,
        providerId: providerId ?? '',
      };
      LOG_ERROR('Error: ProviderActions>>GetProviderData', referenceData);
      dispatch({
        message: ERROR_MESSAGES.update,
        type: ActionConsts.Provider.GetProviderDataFail,
      });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  GetAllProviderData: () => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Provider.GetAllProviderDataInit });
      const result = await ProviderService.GetAllProviderData();
      if (result?.status === 200) {
        const data = {};
        for (const doctor of result?.data || []) {
          if (!doctor?.id) continue;
          data[doctor.id] = {
            id: doctor?.id,
            name: doctor?.name,
            preferredLanguages: doctor?.preferredLanguages,
            designation: doctor?.qualifications?.join(',') || '',
            linkName: doctor?.profileLink,
            image: doctor?.profileImage || '/assets/svgs/avatar.svg',
            signature: doctor?.signature || '/assets/svgs/s-default.svg',
            experience:
              doctor?.totalExperience === 1 || doctor?.totalExperience === 0
                ? '1 yr exp'
                : `${doctor?.totalExperience} yr exp`,
            certification: doctor?.certification?.join(',') || '',
            gender: doctor?.gender,
            display: Boolean(doctor?.isAcceptingNewPatients && doctor?.profileImage),
            regno: doctor?.registrationNumber,
            email: doctor?.email,
            patientsTreated: doctor?.patientsTreated,
            rating: generateRandomNumber(),
            isPhysician: doctor?.isPhysician,
            isTherapist: doctor?.isTherapist,
          };
        }
        dispatch({
          allProviderData: data,
          type: ActionConsts.Provider.GetAllProviderDataSuccess,
        });
      } else {
        dispatch({ type: ActionConsts.Provider.GetAllProviderDataFail });
      }
    } catch (error) {
      LOG_ERROR('Error: ProviderActions>>GetAllProviderData', error);
      dispatch({
        message: ERROR_MESSAGES.update,
        type: ActionConsts.Provider.GetAllProviderDataFail,
      });
    }
  },
  GetTherapistData: (therapistId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Provider.GetTherapistDataInit });
      const result = await ProviderService.GetProviderData(therapistId);
      if (result?.status === 200) {
        dispatch({
          therapistData: {
            id: result?.data?.id,
            name: result?.data?.name,
            preferredLanguages: result?.data?.preferredLanguages,
            designation: result?.data?.qualifications?.join(',') || '',
            linkName: result?.data?.profileLink,
            image: result?.data?.profileImage || '/assets/svgs/avatar.svg',
            signature: result?.data?.signature || '/assets/svgs/s-default.svg',
            experience: `${result?.data?.totalExperience} yr exp`,
            certification: result?.data?.certification?.join(',') || '',
            gender: result?.data?.gender,
            display: Boolean(result?.data?.isAcceptingNewPatients && result?.data?.profileImage),
            regno: result?.data?.registrationNumber,
            email: result?.data?.email,
          } as IProviderData,
          type: ActionConsts.Provider.GetTherapistDataSuccess,
        });
      } else {
        dispatch({ type: ActionConsts.Provider.GetTherapistDataFail });
      }
    } catch (error) {
      const referenceData = {
        error: error,
        therapistId: therapistId ?? '',
      };
      LOG_ERROR('Error: ProviderActions>>GetTherapistData', referenceData);
      dispatch({
        message: ERROR_MESSAGES.update,
        type: ActionConsts.Provider.GetTherapistDataFail,
      });
    }
  },
  GetOfflineAvailabilityForProvider:
    (requestPayload: { providerId: string; typeId: string; consultationCode: string }) =>
    async (dispatch, getState) => {
      try {
        if (!requestPayload?.providerId) return;
        dispatch({ type: ActionConsts.Provider.GetOfflineAvailabilityInit });
        const startDate = new Date();
        const endDate = addDays(
          new Date(),
          getEndDate(requestPayload?.consultationCode, undefined, getState().auth.user.role),
        );

        const result = await ProviderService.GetOfflineAvailabilityForProvider({
          id: requestPayload?.providerId,
          typeId: requestPayload?.typeId,
          startTime: startDate,
          endTime: endDate,
        });

        if (result.status === 200)
          dispatch({
            locationIds: result?.data,
            providerId: requestPayload?.providerId,
            type: ActionConsts.Provider.GetOfflineAvailabilitySuccess,
          });
        else
          dispatch({
            type: ActionConsts.Provider.GetOfflineAvailabilityFail,
          });
      } catch {
        dispatch({ type: ActionConsts.Provider.GetOfflineAvailabilityFail });
      }
    },
  GetOfflineAvailabilityForProviderFuture: createAsyncAction({
    initAction: ActionConsts.Provider.GetOfflineAvailabilityInit,
    successAction: ActionConsts.Provider.GetOfflineAvailabilitySuccess,
    failAction: ActionConsts.Provider.GetOfflineAvailabilityFail,
    returnResult: true,

    asyncFn: async (
      requestPayload: ActionInputs['GetOfflineAvailabilityForProviderFuture'],
      getState: () => RootState,
    ) => {
      const startDate = new Date();
      const endDate = addDays(
        new Date(),
        getEndDate(requestPayload?.consultationCode, undefined, getState().auth.user.role),
      );
      const response = await ProviderService.GetOfflineAvailabilityForProvider({
        id: requestPayload?.providerId,
        typeId: requestPayload?.typeId,
        startTime: startDate,
        endTime: endDate,
      });
      return response?.status === 200
        ? {
            type: 'success',
            result: {
              locationIds: response?.data,
              providerId: requestPayload?.providerId,
            },
          }
        : { type: 'fail', result: undefined };
    },
  }),
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetProviderAvailability: () => (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Provider.GetOfflineAvailabilityReset });
    } catch (error) {
      LOG_ERROR('Error: ProviderActions>>ResetOfflineAvailability', error);
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetProviderData: () => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Provider.ResetProviderData });
    } catch (error) {
      LOG_ERROR('Error: ProviderActions>>ResetProviderData', error);
    }
  },
};
