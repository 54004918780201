import { ActionConsts } from '@Definitions/ActionConsts';

export interface Locality {
  type: string;
  eloc: string;
  placeName: string;
  placeAddress: string;
}

const INITIAL_STATE = {
  localities: [] as Locality[],
  isLoading: false,
};

export const LocalityReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Location.GetRedcliffeLocalitiesInit:
      return {
        ...state,
        isLoading: true,
      };
    case ActionConsts.Location.GetRedcliffeLocalitiesSuccess:
      return {
        ...state,
        localities: action.localities,
        isLoading: false,
      };
    case ActionConsts.Location.GetRedcliffeLocalitiesFail:
      return {
        ...state,
        localities: [],
        isLoading: false,
      };
    default:
      return state;
  }
};

export default LocalityReducer;
