import { Dispatch } from '@reduxjs/toolkit';

import createAsyncAction from '@Actions/create-async-action';
import { ActionConsts } from '@Definitions/index';
import OnlineBanner from '@Images/product-banners/appointment-online-banner.png';
import { ScheduleService } from '@Services/index';
import { LocationService } from '@Services/location';
import { RedcliffeService } from '@Services/Redcliffe';
import { GetLocationProvidersMap } from '@Services/Schedule/schedule-payload';
import { DEFAULT_LOCATION_CODE } from '@Utils/constants';
import { arrangeOfflineClinicsByCity, getClinicCodeToCityMap } from '@Utils/Helpers/location/helper';
import { LOG_ERROR } from '@Utils/logger';

export const LocationActions = {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  GetClinicLocations: () => async (dispatch, getState) => {
    try {
      dispatch({ type: ActionConsts.Location.GetClinicLocationsInit });
      const result = await ScheduleService.GetClinicLocations();
      if (result?.status === 200) {
        const offlineClinicLocations = arrangeOfflineClinicsByCity(result?.data);
        const onlineLocation = result?.data?.find((location) => location.code === DEFAULT_LOCATION_CODE);

        dispatch({
          offlineClinicLocations: offlineClinicLocations,
          clinicLocationToCity: getClinicCodeToCityMap(result?.data),
          onlineLocation: {
            id: onlineLocation?.id,
            code: onlineLocation?.code,
            images: [OnlineBanner.src],
          },
          type: ActionConsts.Location.GetClinicLocationsSuccess,
        });
      } else {
        dispatch({
          message: result?.data?.message,
          type: ActionConsts.Location.GetClinicLocationsFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: ScheduleActions>>GetClinicLocations', { error });
      dispatch({ type: ActionConsts.Location.GetClinicLocationsFail });
    }
  },
  GetClinicLocationsFuture: createAsyncAction({
    initAction: ActionConsts.Location.GetClinicLocationsInit,
    successAction: ActionConsts.Location.GetClinicLocationsSuccess,
    failAction: ActionConsts.Location.GetClinicLocationsFail,
    returnResult: true,

    asyncFn: async () => {
      const response = await ScheduleService.GetClinicLocations();
      if (response?.status === 200) {
        const offlineClinicLocations = arrangeOfflineClinicsByCity(response?.data);
        const onlineLocation = response?.data?.find((location) => location.code === DEFAULT_LOCATION_CODE);

        return {
          type: 'success',
          result: {
            offlineClinicLocations: offlineClinicLocations,
            clinicLocationToCity: getClinicCodeToCityMap(response?.data),
            onlineLocation: {
              id: onlineLocation?.id,
              code: onlineLocation?.code,
              images: [OnlineBanner.src],
            },
          },
        };
      } else return { type: 'fail', result: undefined };
    },
  }),
  GetClinicLocation: (locationId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Location.GetClinicLocationInit });
      const result = await ScheduleService.GetClinicLocation(locationId);
      if (result?.status === 200) {
        dispatch({
          offlineClinicLocation: result?.data,
          type: ActionConsts.Location.GetClinicLocationSuccess,
        });
      } else {
        dispatch({
          type: ActionConsts.Location.GetClinicLocationFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: ScheduleActions>>GetClinicLocation', error);
      dispatch({
        type: ActionConsts.Location.GetClinicLocationFail,
      });
    }
  },
  GetProviderLocations: (requestPayload: GetLocationProvidersMap) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Location.GetProviderLocationsInit });
      const result = await LocationService.GetProviderLocations(requestPayload);
      if (result?.status === 200) {
        dispatch({
          locationsWithProviders: result?.data,
          type: ActionConsts.Location.GetProviderLocationsSuccess,
        });
      } else {
        dispatch({
          type: ActionConsts.Location.GetProviderLocationsFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: LocationActions>>GetProviderLocations', error);
      dispatch({
        type: ActionConsts.Location.GetProviderLocationsFail,
      });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetClinicLocationsData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Location.ResetClinicLocationsData,
    });
  },
  GetRedcliffeLocalities: createAsyncAction({
    initAction: ActionConsts.Location.GetRedcliffeLocalitiesInit,
    successAction: ActionConsts.Location.GetRedcliffeLocalitiesSuccess,
    failAction: ActionConsts.Location.GetRedcliffeLocalitiesFail,

    asyncFn: async (requestPayload: { query: string }) => {
      const response = await RedcliffeService.GetRedcliffeLocalities(requestPayload);
      if (response.status === 200) {
        return { result: { localities: response.data }, type: 'success' };
      }
      return { result: { localities: [] }, type: 'fail' };
    },
  }),
};
