import { Dispatch } from '@reduxjs/toolkit';

import { GetResourcesPayload } from '@Actions/PatientActions/payload';
import { ActionConsts } from '@Definitions/ActionConsts';
import { ResourceService } from '@Services/Resource';
import { formatResources } from '@Utils/Helpers/resource.helper';
import { LOG_ERROR } from '@Utils/logger';

export const ResourceActions = {
  GetResources: (requestPayload: GetResourcesPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Resource.GetResourcesInit });
      const result = await ResourceService.GetPatientResources(requestPayload);
      if (result?.status === 200) {
        dispatch({
          resources: formatResources(result?.data),
          type: ActionConsts.Resource.GetResourcesSuccess,
        });
      } else {
        dispatch({ type: ActionConsts.Resource.GetResourcesFail });
      }
    } catch (error) {
      const referenceData = {
        requestPayload: requestPayload ?? {},
        error: error,
      };
      LOG_ERROR('Error: ResourceActions>>GetResources', referenceData);
      dispatch({ type: ActionConsts.Resource.GetResourcesFail });
    }
  },
};
