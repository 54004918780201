import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/index';
import { LabVenue } from '@Reducers/encounter';
import { ConsultationMode, ScheduleType, UpdateQueryDataPayload } from '@Services/Common/common-payload';
import { ItemType } from '@Utils/constants';
import { consultationCodeTransform } from '@Utils/helper';
import { FileType } from '@Utils/Helpers/PatientFiles/helper';
import { LOG_ERROR } from '@Utils/logger';

export const CommonActions = {
  /* eslint-disable-next-line complexity */
  UpdateQueryData: (requestPayload: UpdateQueryDataPayload) => async (dispatch: Dispatch) => {
    //either location code or consultation mode should be valid
    const data = {
      ...(requestPayload?.location_code !== undefined && {
        locationCode: requestPayload?.location_code,
      }),
      ...(requestPayload?.token && {
        hmsToken: requestPayload?.token?.toString(),
      }),
      ...(requestPayload?.provider_id !== undefined && {
        providerId: requestPayload?.provider_id
          ?.toString()
          ?.split(',')
          ?.filter((item) => item !== ''),
      }),
      ...(requestPayload?.excluded_provider_id && {
        excludedProviderId: requestPayload?.excluded_provider_id?.toString().split(','),
      }),
      ...(requestPayload?.reason && {
        reason: requestPayload?.reason?.toString(),
      }),
      ...(requestPayload?.form_type && {
        formType: requestPayload?.form_type?.toString(),
      }),
      ...(requestPayload?.voucher_code != undefined && {
        voucherCode: requestPayload?.voucher_code?.toString()?.toUpperCase(),
      }),
      ...(requestPayload?.product_code && {
        consultationCode: consultationCodeTransform(requestPayload?.product_code?.toString()),
      }),
      ...(requestPayload?.page_source && {
        pageSource: requestPayload?.page_source?.toString(),
      }),
      ...(requestPayload?.source_url && {
        sourceUrl: requestPayload?.source_url?.toString(),
      }),
      ...(requestPayload?.name && {
        name: requestPayload?.name?.toString(),
      }),
      ...(requestPayload?.phone && {
        phone: requestPayload?.phone?.toString(),
      }),
      ...(requestPayload?.email && {
        email: requestPayload?.email?.toString(),
      }),
      ...(requestPayload?.date && {
        date: requestPayload?.date?.toString(),
      }),
      ...(requestPayload?.appointment_id != undefined && {
        appointmentId: requestPayload?.appointment_id?.toString(),
      }),
      ...(requestPayload?.appointment_state != undefined && {
        appointmentState: requestPayload?.appointment_state?.toString(),
      }),
      ...(requestPayload?.ipx_id != undefined && {
        ipxId: requestPayload?.ipx_id?.toString(),
      }),
      ...(requestPayload?.embedded != undefined && {
        embedded: requestPayload?.embedded?.toString(),
      }),
      ...(requestPayload?.consultation_id != undefined && {
        consultationId: requestPayload?.consultation_id?.toString(),
      }),
      ...(requestPayload?.selected_languages != undefined && {
        selectedLanguages: Array.isArray(requestPayload?.selected_languages)
          ? requestPayload?.selected_languages
          : [requestPayload?.selected_languages],
      }),
      ...(requestPayload?.enc_id != undefined && {
        encounterId: requestPayload?.enc_id?.toString(),
      }),
      ...(requestPayload?.encounter_id != undefined && {
        encounterId: requestPayload?.encounter_id?.toString(),
      }),
      ...(requestPayload?.consultation_state != undefined && {
        consultationState: requestPayload?.consultation_state?.toString(),
      }),
      ...(requestPayload?.confirmation_type != undefined && {
        confirmationType: requestPayload?.confirmation_type?.toString(),
      }),
      ...(requestPayload?.utm_term && {
        utmTerm: requestPayload?.utm_term?.toString(),
      }),
      ...(requestPayload?.utm_source && {
        utmSource: requestPayload?.utm_source?.toString(),
      }),
      ...(requestPayload?.utm_medium && {
        utmMedium: requestPayload?.utm_medium?.toString(),
      }),
      ...(requestPayload?.utm_campaign && {
        utmCampaign: requestPayload?.utm_campaign?.toString(),
      }),
      ...(requestPayload?.utm_content && {
        utmContent: requestPayload?.utm_content?.toString(),
      }),
      ...(requestPayload?.gclid && {
        gclid: requestPayload?.gclid?.toString(),
      }),
      ...(requestPayload?.fbclid && {
        fbclid: requestPayload?.fbclid?.toString(),
      }),
      ...(requestPayload?.fbc && {
        fbc: requestPayload?.fbc?.toString(),
      }),
      ...(requestPayload?.fbp && {
        fbp: requestPayload?.fbp?.toString(),
      }),
      ...(requestPayload?.assessment_language && {
        assessmentLanguage: requestPayload?.assessment_language?.toString(),
      }),
      ...(Object.values(ConsultationMode).includes(
        requestPayload.consultation_mode?.toLowerCase() as ConsultationMode,
      ) && {
        consultationMode: requestPayload?.consultation_mode.toLowerCase(),
      }),
      ...(requestPayload?.invoice_mode && {
        invoiceMode: requestPayload?.invoice_mode?.toString(),
      }),
      ...(requestPayload.valid_user_city && {
        validUserCity: requestPayload.valid_user_city?.toString(),
      }),
      ...(requestPayload.speciality !== undefined && {
        speciality: requestPayload.speciality?.toString(),
      }),
      ...(requestPayload.lab_venue !== undefined && {
        labVenue: Object.values(LabVenue).includes(requestPayload.lab_venue as LabVenue)
          ? requestPayload.lab_venue
          : undefined,
      }),
      ...(requestPayload?.inventory_item_id !== undefined && {
        inventoryItemId: requestPayload?.inventory_item_id?.toString(),
      }),
      ...(requestPayload?.inventory_item_type !== undefined && {
        inventoryItemType: Object.values(ItemType).includes(requestPayload?.inventory_item_type?.toString() as ItemType)
          ? requestPayload?.inventory_item_type?.toString()
          : undefined,
      }),
      ...(requestPayload?.surcharge !== undefined && {
        surcharge: Number(requestPayload?.surcharge),
      }),
      ...(requestPayload?.is_instant !== undefined && {
        isInstant: Boolean(requestPayload?.is_instant as boolean),
      }),
      ...(requestPayload?.schedule_type !== undefined &&
        Object.values(ScheduleType).includes(requestPayload?.schedule_type) && {
          scheduleType: requestPayload?.schedule_type.toString(),
        }),
      ...(requestPayload.selected_address && {
        selectedAddress: requestPayload?.selected_address,
      }),
      ...(requestPayload.collection_date && {
        collectionDate: requestPayload?.collection_date,
      }),
      ...(requestPayload.collection_slot_id && {
        collectionSlotId: requestPayload?.collection_slot_id,
      }),
      ...(requestPayload?.upload_type !== undefined &&
        Object.values(FileType).includes(requestPayload?.upload_type?.toString() as FileType) && {
          uploadType: requestPayload?.upload_type?.toString(),
        }),
    };
    const bookCallParams = {
      ...(requestPayload.provider_id && {
        providerId: requestPayload.provider_id,
      }),
      ...(requestPayload.location_code && {
        locationId: requestPayload.location_code,
      }),
      ...(requestPayload.type_id && {
        typeId: requestPayload.type_id,
      }),
      ...(requestPayload.start_time && {
        startDate: requestPayload.start_time,
      }),
      ...(requestPayload.end_time && {
        endDate: requestPayload.end_time,
      }),
      ...(requestPayload.block_id && {
        blockId: requestPayload.block_id,
      }),
      ...(requestPayload.open_payment_modal && {
        openPaymentModal: requestPayload?.open_payment_modal,
      }),
    };

    try {
      dispatch({
        queryData: data,
        bookCallParams: bookCallParams,
        type: ActionConsts.Common.UpdateQueryData,
      });
    } catch (error) {
      LOG_ERROR('Error: CommonActions>>UpdateQueryData', error);
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetQueryData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Common.ResetQueryData,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetbookCallParams: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Common.ResetbookCallParams,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetQueryExceptIpxIdData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Common.ResetQueryDataExceptIpxId,
    });
  },
};
