import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/ActionConsts';
import { PatientService, UserService } from '@Services/index';
import { UpdatePatientPayload } from '@Services/Patient/patient-payload';
import { UserRole } from '@Utils/constants';
import { getUserBasedOnIpxId, getValidEvaluationState, updateUserBasedOnIpxId } from '@Utils/Helpers/user/helper';
import { LOG_ERROR } from '@Utils/logger';

export const UserActions = {
  UpdateUser: (payload: UpdatePatientPayload) => async (dispatch: Dispatch, getState) => {
    try {
      dispatch({ type: ActionConsts.User.UpdateInit });
      const result = await updateUserBasedOnIpxId(payload, getState()?.auth?.user?.role || UserRole.PATIENT);
      if (result) {
        dispatch({
          type: ActionConsts.User.UpdateSuccess,
          userData: result,
        });
      } else {
        dispatch({
          type: ActionConsts.User.UpdateFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: UserActions>>UpdateUser', { error });
      dispatch({
        type: ActionConsts.User.UpdateFail,
      });
    }
  },

  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetUserData: () => async (dispatch: Dispatch) => {
    dispatch({ type: ActionConsts.User.ResetUserData });
  },

  GetEvaluationState: (payload: { ipxId?: string }) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.User.GetEvaluationStateInit });
      const response = await UserService.GetEvaluationState(payload);
      if (response && response.status === 200) {
        dispatch({
          type: ActionConsts.User.GetEvaluationStateSuccess,
          evaluationState: getValidEvaluationState(response?.data),
        });
      } else {
        dispatch({
          type: ActionConsts.User.GetEvaluationStateFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: UserActions>>GetEvaluationState', {
        error,
      });
      dispatch({
        type: ActionConsts.User.GetEvaluationStateFail,
      });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetEvaluationState: () => async (dispatch: Dispatch) => {
    dispatch({ type: ActionConsts.User.ResetEvaluationState });
  },
  GetUser: (userId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.User.GetUserInit });
      const result = await getUserBasedOnIpxId(userId);
      if (result) {
        dispatch({
          type: ActionConsts.User.GetUserSuccess,
          userData: result,
        });
      } else {
        dispatch({
          type: ActionConsts.User.GetUserFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: UserActions>>GetUser', { error });
      dispatch({
        type: ActionConsts.User.GetUserFail,
      });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  GetIpCity: () => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ActionConsts.User.GetIpCityInit,
      });
      const result = await UserService.GetUserIpCity();
      if (result.status === 200) {
        dispatch({
          city: result.data?.city?.toLowerCase(),
          coordinates: {
            latitude: result.data?.latitude,
            longitude: result.data?.longitude,
          },
          type: ActionConsts.User.GetIpCitySuccess,
        });
      } else dispatch({ type: ActionConsts.User.GetIpCityFail });
    } catch (error) {
      LOG_ERROR('UserActions>>>GetIpCity', error);
      dispatch({ type: ActionConsts.User.GetIpCityFail });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  GetUserPsuedoDiagnoses: () => async (dispatch: Dispatch) => {
    try {
      const result = await PatientService.GetPatientPsuedoDiagnoses();
      if (result.status === 200) {
        dispatch({
          type: ActionConsts.User.GetUserPsuedoDiagnosesSuccess,
          userPsuedoDiagnoses: result.data.map((item) => ({
            name: item?.medicalCondition?.name,
            severity: item?.severity,
          })),
        });
      }
    } catch (error) {
      LOG_ERROR('UserActions>>>GetUserPsuedoDiagnoses', error);
    }
  },
};
