import Image from 'next/image';
import React, { useEffect } from 'react';
const ClinicGallery = ({ images }: { images: string[] }) => {
  const [selectedImage, setSelectedImage] = React.useState<string>(images?.[0]);
  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedImage(images?.[Math.floor(Math.random() * images.length)]);
    }, 2500);
    return () => clearInterval(interval);
  }, [images]);
  return <Image className='rounded-lg brightness-75' src={selectedImage} width={122} height={88} alt='clinic' />;
};

export default ClinicGallery;
