import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import createAsyncAction from '@Actions/create-async-action';
import { ActionConsts } from '@Definitions/ActionConsts';
import { TimePeriod } from '@Reducers/schedule';
import { ISlotData } from '@Reducers/slot/types';
import { RootState } from '@Redux/store';
import { ScheduleService } from '@Services';
import { RedcliffeService } from '@Services/Redcliffe';
import { CONSULTATION_TYPE, DEFAULT_LOCATION_CODE, ERROR_MESSAGES, ONLINE_LOCATION_ID } from '@Utils/constants';
import { convertInstantSlots, groupSlotsByDate } from '@Utils/Helpers/schedule/helper';
import { LOG_ERROR } from '@Utils/logger';

import ActionInputs from './payload';

export const SlotActions = {
  PreviewSlots: createAsyncAction({
    initAction: ActionConsts.Slot.GetPreviewSlotsInit,
    successAction: ActionConsts.Slot.GetPreviewSlotsSuccess,
    failAction: ActionConsts.Slot.GetPreviewSlotsFail,

    asyncFn: async (requestPayload: ActionInputs['PreviewSlots']) => {
      const response = await ScheduleService.GetSlots({
        preview: true,
        consultationTypeId: requestPayload.consultationTypeId,
        startDate: dayjs().toISOString(),
        endDate: dayjs().add(1.5, 'hour').toISOString(),
        providerIds: requestPayload.providerId ? [requestPayload.providerId] : [],
        locationId: ONLINE_LOCATION_ID,
      });

      if (response.status === 200) {
        const groupedSlots = groupSlotsByDate(response.data, false, true);
        const instantPreviewSlots = groupedSlots?.[dayjs().format('YYYY-MM-DD')]?.[TimePeriod.Instant];
        return { result: { instantPreviewSlots }, type: 'success' };
      }
      return { result: [], type: 'fail' };
    },
  }),
  GetSlots: createAsyncAction({
    initAction: ActionConsts.Slot.GetSlotsInit,
    successAction: ActionConsts.Slot.GetSlotsSuccess,
    failAction: ActionConsts.Slot.GetSlotsFail,

    asyncFn: async (requestPayload: ActionInputs['GetSlots'], getState: () => RootState) => {
      const response = await ScheduleService.GetSlots(requestPayload);

      if (response.status === 200) {
        //if screening call and online restrict slot visibility
        const isScreeningCallAndOnline =
          getState().common.queryData?.locationCode === DEFAULT_LOCATION_CODE &&
          getState().consultation.consultationType?.consultationCode === CONSULTATION_TYPE.SC &&
          !Boolean(getState().common.queryData?.utmContent === 'instant');

        const showInstantSlots: boolean = isScreeningCallAndOnline;
        return {
          result: {
            slotsData: convertInstantSlots(groupSlotsByDate(response.data, isScreeningCallAndOnline, showInstantSlots)),
          },
          type: 'success',
        };
      } else return { result: [], type: 'fail' };
    },
  }),
  GetProvidersBySlots: createAsyncAction({
    initAction: ActionConsts.Slot.GetProvidersBySlotsInit,
    successAction: ActionConsts.Slot.GetProvidersBySlotsSuccess,
    failAction: ActionConsts.Slot.GetProvidersBySlotsFail,

    asyncFn: async (requestPayload: null, getState: () => RootState) => {
      const slots: ISlotData = getState().slot.activeSlots.slots;
      const providers: Set<string> = new Set();
      for (const [day, slotsForDay] of Object.entries(slots)) {
        for (const [timePeriod, slotsForTimePeriod] of Object.entries(slotsForDay)) {
          for (const slot of slotsForTimePeriod) {
            providers.add(slot.providerId);
          }
        }
      }
      return { result: { providers }, type: 'success' };
    },
  }),
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetSlots: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Slot.ResetSlots,
    });
  },
  GetRedcliffeSlots: (requestPayload: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ActionConsts.Slot.GetRedcliffeSlotsInit,
      });
      const response = await RedcliffeService.GetRedcliffeSlots(requestPayload);
      if (response.status === 200) {
        dispatch({
          type: ActionConsts.Slot.GetRedcliffeSlotsSuccess,
          redcliffeSlots: response.data,
        });
        return response.data;
      } else {
        dispatch({
          type: ActionConsts.Slot.GetRedcliffeSlotsFail,
        });
        if (response?.data?.message === ERROR_MESSAGES.location) {
          throw new Error(ERROR_MESSAGES.location);
        }
      }
    } catch (error) {
      dispatch({
        type: ActionConsts.Slot.GetRedcliffeSlotsFail,
      });
      if (error.message === ERROR_MESSAGES.location) {
        throw error;
      } else {
        LOG_ERROR('Error: GetRedcliffeSlots>>SlotActions', error);
      }
    }
  },
};
