import clsx from 'clsx';
import Image from 'next/image';

const SelectionCardHeader = ({
  isSelected,
  imageSrc,
  title,
  handleSelection,
  children,
  footer,
  headerClass,
  id,
}: {
  isSelected: boolean;
  imageSrc: string;
  title: React.ReactNode;
  handleSelection?: (e?) => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
  headerClass?: string;
  id?: string;
}) => {
  return (
    <div
      id={id}
      className={`bg-white rounded-xl shadow-lg text-left ${
        isSelected ? 'border-4 border-PRIMARY_600' : 'border border-PRIMARY_100'
      } relative overflow-hidden`}
      onClick={handleSelection}>
      <div className={clsx('flex justify-between items-center', headerClass)}>
        <div className='flex gap-3 items-start'>
          <div>
            <Image src={imageSrc} height={24} width={24} alt='online' />
          </div>
          {title}
        </div>
      </div>
      {children}
      {footer && <div className='border-t border-GREYS_400'>{footer}</div>}
    </div>
  );
};
export default SelectionCardHeader;
