import { AxiosResponse } from 'axios';

import { GetResourcesPayload } from '@Actions/PatientActions/payload';
import { Axios } from '@Services/AxiosApiServerWrapper';

export const ResourceService = {
  GetPatientResources: async (payload: GetResourcesPayload): Promise<AxiosResponse> => {
    const url = `article`;
    return Axios.Request('GET', url, {}, payload);
  },
};
