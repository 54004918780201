import clsx from 'clsx';
import Image from 'next/image';

import ClinicDetails from '@Components/clinic-details';
import { ColorType, FontType } from '@Components/common-exports';
import SelectionCardHeader from '@Components/core/selection-card-header';
import { Button } from '@Components/design-system/button/button';
import Text from '@Components/design-system/text';
import DoctorCardV1 from '@Components/doctor-cards/version-1';
import OfflineMode from '@Images/svgs/consultation-clinic.svg';
import { IProviderData } from '@Reducers/provider';
import { IClinicLocationsData } from '@Reducers/schedule';

const Offline = ({
  nearestClinic,
  clinicsLocalities,
  clincProviders,
  browseClinics,
  changeLocation,
  isSelected,
}: {
  nearestClinic: IClinicLocationsData;
  clinicsLocalities: string[];
  clincProviders: IProviderData[];
  browseClinics: () => void;
  changeLocation: (locationCode: string) => void;
  isSelected: boolean;
}) => {
  return (
    <section>
      <SelectionCardHeader
        id='consult-offline-option'
        isSelected={true}
        imageSrc={OfflineMode}
        headerClass='p-4 border-b border-GREYS_400'
        title={
          <div>
            <Text color={ColorType.GREYS_900} font={FontType.SEMIBOLD_14_150}>
              Visit Nearest Clinic
            </Text>
          </div>
        }
        footer={
          <div className=''>
            <div
              className={clsx(
                'py-3',
                'flex gap-2 items-center justify-center',
                clinicsLocalities?.length > 0 ? 'block' : 'hidden',
              )}>
              <Image src={'/assets/svgs/clinic-primary.svg'} height={16} width={16} alt='allo-icon' />
              <Text color={ColorType.GREYS_900} font={FontType.REGULAR_12_170} className='text-center'>
                {clinicsLocalities?.slice(0, 2).join(', ')}{' '}
                {clinicsLocalities?.length - 2 > 0 &&
                  `
                &
                ${clinicsLocalities?.length - 2}+ more clinics
                `}
              </Text>
            </div>
            <div className='bg-PRIMARY_25 py-2 grid grid-cols-2 px-4 items-center'>
              <Text
                id='see-nearby-clinics'
                className='relative flex'
                onClick={(e) => {
                  e.stopPropagation();
                  browseClinics();
                }}
                color={ColorType.INDIGO_600}
                font={FontType.SEMIBOLD_12_170}>
                See clinics near you &gt;
              </Text>

              <Button
                label={
                  <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_100}>
                    Book Visit
                  </Text>
                }
                fullWidth={true}
                className={`cursor-pointer`}
                id='book-nearest-clinic'
                onPress={(e) => {
                  e.stopPropagation();
                  changeLocation(nearestClinic.code);
                }}
                type='secondary'
                borderRadius={50}
                size='large'
                align='center'
              />
            </div>
          </div>
        }
        handleSelection={() => {
          changeLocation(nearestClinic.code);
        }}>
        <div className='absolute top-0 right-4 flex gap-1 bg-PRIMARY_600 w-24 py-1 justify-center rounded-b-lg '>
          <Image src={'/assets/svgs/star-grey.svg'} height={12} width={12} alt='allo-icon' />
          <Text font={FontType.SEMIBOLD_8_170} color={ColorType.GREYS_100} className='z-10'>
            PAY AT CLINIC
          </Text>
        </div>
        <div className='px-4 py-3'>
          <ClinicDetails clinic={nearestClinic} />
        </div>
        <div className='flex gap-4 px-4 overflow-x-scroll pb-3'>
          {clincProviders?.map((provider, index) => (
            <div key={provider?.name} className='min-w-[90%]'>
              <DoctorCardV1
                doctorImage={provider?.image}
                title={`Will be meeting ${provider?.name}`}
                description={`${provider?.designation} | ${provider?.experience}`}
              />
            </div>
          ))}
        </div>
      </SelectionCardHeader>
    </section>
  );
};

export default Offline;
