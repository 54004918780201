import { AxiosResponse } from 'axios';

import { GetConsultationDetailsPayload } from '@Services/Order/order-payload';
import { InvoiceStatus } from '@Utils/Helpers/Order/helper';

import { Axios } from '../AxiosApiServerWrapper';

export const OrderService = {
  GetConsultationDetails: async (payload: GetConsultationDetailsPayload): Promise<AxiosResponse> => {
    const { consultationCode, locationId } = payload;
    const locationParam = locationId ? `?locationId=${locationId}` : '';
    const url = `catalog/consultation/code/${consultationCode}${locationParam}`;
    return Axios.Request('GET', url);
  },
  GetInvoiceForEncounter: async (payload: { encounterId: string; ipxId?: string }): Promise<AxiosResponse> => {
    const { encounterId, ...body } = payload;
    const url = `fx/shipping/encounters/${encounterId}`;
    return Axios.Request('GET', url, {}, body);
  },
  GetPatientInvoices: async (payload: {
    statuses?: InvoiceStatus[];
    ipxId?: string;
    encounterId?: string;
  }): Promise<AxiosResponse> => {
    const url = 'invoices';
    return Axios.Request('GET', url, {}, payload);
  },
  GetConsultationOrders: async (ipxId): Promise<AxiosResponse> => {
    const url = `consultations`;
    return Axios.Request('GET', url, {}, { patientId: ipxId });
  },
  GetConsultationOrdersFromInvoice: async (invoiceId, ipxId): Promise<AxiosResponse> => {
    const url = `fulfilment/invoices/${invoiceId}/consultations`;
    return Axios.Request('GET', url, {}, { ipxId });
  },
  GetLabTestOrders: async (ipxId?): Promise<AxiosResponse> => {
    const url = `fulfillments/labs/orders`;
    return Axios.Request('GET', url, {}, { ipxId });
  },
  GetPendingOrders: async (payload: { ipxId: string; showArticles?: boolean }): Promise<AxiosResponse> => {
    const { ipxId, showArticles } = payload;
    const url = `encounters/dashboard-items`;
    return Axios.Request('GET', url, {}, { ipxId, showArticles });
  },
};
