import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/index';
import { PatientService } from '@Services/index';
import { GetPatientMetadataPayload, UpdateEncountersPayload, IAddress } from '@Services/Patient/patient-payload';
import { ERROR_MESSAGES } from '@Utils/constants';
import { getAllAddresses, getDiagnoses, getUniqueEncounters, sortEncounters } from '@Utils/Helpers/Patient/helper';
import { LOG_ERROR } from '@Utils/logger';

export const PatientActions = {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetPatientData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Patient.ResetPatientData,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  GetPatientDiagnoses: () => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Patient.GetPatientDiagnosesInit });
      const result = await PatientService.GetPatientDiagnoses();
      if (result?.status === 200) {
        dispatch({
          diagnosesData: result?.data?.map((diagnoses) => diagnoses['description']),
          type: ActionConsts.Patient.GetPatientDiagnosesSuccess,
        });
      } else {
        dispatch({ type: ActionConsts.Patient.GetPatientDiagnosesFail });
      }
    } catch (error) {
      const referenceData = {
        error: error,
      };
      LOG_ERROR('Error: PatientActions>>GetPatientDiagnoses', referenceData);
      dispatch({
        message: ERROR_MESSAGES.get,
        type: ActionConsts.Patient.GetPatientDiagnosesFail,
      });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetReportsData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Patient.ResetPatientReports,
    });
  },
  GetPatientMetadata: (requestPayload: GetPatientMetadataPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Patient.GetPatientMetadataInit });
      const result = await PatientService.GetPatientMetadata(requestPayload);
      if (result?.status === 200) {
        dispatch({
          metadataData: (result?.data ?? []).map((metadata) => ({
            id: metadata?.id,
            referenceId: metadata?.referenceId,
            metadataType: metadata?.metadataType,
            seqNo: metadata?.seqNo,
            title: metadata?.title,
            description: metadata?.description,
          })),
          type: ActionConsts.Patient.GetPatientMetadataSuccess,
        });
      } else {
        dispatch({ type: ActionConsts.Patient.GetPatientMetadataFail });
      }
    } catch (error) {
      const referenceData = {
        error: error,
      };
      LOG_ERROR('Error: PatientActions>>GetPatientMetadata', referenceData);
      dispatch({
        message: ERROR_MESSAGES.get,
        type: ActionConsts.Patient.GetPatientMetadataFail,
      });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetMetadataData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Patient.ResetPatientMetadata,
    });
  },
  GetAllPatientEncounters:
    (requestPayload: { patientId: string; types?: string[]; ipxId?: string }) => async (dispatch: Dispatch) => {
      try {
        dispatch({ type: ActionConsts.Patient.GetAllPatientEncountersInit });
        const result = await PatientService.GetPatientEncounters(requestPayload);
        if (!Array.isArray(result?.data)) {
          result.data = [result.data];
        }
        if (result?.status === 200 && result?.data?.length === 0) {
          dispatch({
            allEncounters: [],
            type: ActionConsts.Patient.GetAllPatientEncountersSuccess,
          });
        } else if (result?.status === 200 && result?.data?.length > 0) {
          const diagnoses = getDiagnoses(result?.data);
          const filteredEncounters = getUniqueEncounters(sortEncounters(result?.data));
          dispatch({
            allEncounters: filteredEncounters,
            diagnoses: diagnoses,
            type: ActionConsts.Patient.GetAllPatientEncountersSuccess,
          });
        } else {
          dispatch({ type: ActionConsts.Patient.GetAllPatientEncountersFail });
        }
      } catch (error) {
        const referenceData = {
          error: error,
          patientId: requestPayload?.patientId ?? '',
        };
        LOG_ERROR('Error: PatientActions>>GetAllPatientEncounters', referenceData);
        dispatch({
          message: ERROR_MESSAGES.get,
          type: ActionConsts.Patient.GetAllPatientEncountersFail,
        });
      }
    },
  UpdateEncounter: (requestPayload: UpdateEncountersPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Patient.UpdateEncounterInit });
      const result = await PatientService.UpdateEncounters(requestPayload);
      if (result?.status === 200) {
        dispatch({
          encounterData: {
            id: result?.data?.id,
            drugsOptional: result?.data?.drugsOptional,
            counsellingOptional: result?.data?.counsellingOptional,
            labTestsOptional: result?.data?.labTestsOptional,
          },
          type: ActionConsts.Patient.UpdateEncounterSuccess,
        });
      } else {
        dispatch({ type: ActionConsts.Patient.UpdateEncounterFail });
      }
    } catch (error) {
      const referenceData = {
        error: error,
        request: requestPayload,
      };
      LOG_ERROR('Error: PatientActions>>UpdateEncounter', referenceData);
      dispatch({
        message: ERROR_MESSAGES.update,
        type: ActionConsts.Patient.UpdateEncounterFail,
      });
    }
  },
  GetAllAddresses: (payload: { userId: string }) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Patient.GetAllAddressInit });
      const response = await PatientService.GetXPatientAddress(payload?.userId);
      if (response?.status === 200) {
        const alladdress = getAllAddresses(response?.data).sort((a, b) => {
          if (a.isDefault && !b.isDefault) {
            return -1;
          } else if (!a.isDefault && b.isDefault) {
            return 1;
          }
        });
        dispatch({
          type: ActionConsts.Patient.GetAllAddressSuccess,
          addresses: alladdress,
        });
      } else {
        dispatch({
          type: ActionConsts.Patient.GetAllAddressFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: PatientActions>>GetAddress', { error });
      dispatch({
        type: ActionConsts.Patient.GetAllAddressFail,
      });
    }
  },

  AddAddress:
    (requestPayload: { newAddress: IAddress; existingAddresses: IAddress[]; isUpdating: boolean; ipxId?: string }) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch({ type: ActionConsts.Patient.AddAddressInit });
        const response = requestPayload?.isUpdating
          ? await PatientService.updateAddress(requestPayload.newAddress, requestPayload?.ipxId)
          : await PatientService.AddPatientAddress(requestPayload?.newAddress, requestPayload?.ipxId);
        if (response?.data?.id && (response.status === 201 || response?.status === 200)) {
          const updatedAddresses = [];
          for (const address of requestPayload.existingAddresses || []) {
            if (address.id !== response?.data?.id && address.isDefault && requestPayload?.newAddress?.isDefault) {
              const updateResponse = await PatientService.updateAddress(
                {
                  ...address,
                  id: address.id.toString(),
                  isDefault: false,
                },
                requestPayload?.ipxId,
              );
              if (updateResponse?.status === 200) {
                updatedAddresses.push({
                  id: updateResponse?.data?.id,
                  street: updateResponse?.data?.street,
                  city: updateResponse?.data?.city,
                  state: updateResponse?.data?.state,
                  pincode: updateResponse?.data?.pincode,
                  country: updateResponse?.data?.country,
                  isDefault: updateResponse?.data?.isDefault,
                });
              }
            } else if (address.id !== response?.data?.id) {
              updatedAddresses.push(address);
            }
          }
          updatedAddresses.push({
            id: response?.data?.id,
            street: response?.data?.street,
            city: response?.data?.city,
            state: response?.data?.state,
            pincode: response?.data?.pincode,
            country: response?.data?.country,
            isDefault: response?.data?.isDefault,
          });
          updatedAddresses.sort((a, b) => {
            if (a.isDefault && !b.isDefault) {
              return -1;
            } else if (!a.isDefault && b.isDefault) {
              return 1;
            }
          });
          dispatch({
            type: ActionConsts.Patient.AddAddressSuccess,
            addresses: updatedAddresses,
          });
        } else {
          dispatch({
            type: ActionConsts.Patient.AddAddressFail,
          });
        }
      } catch (error) {
        LOG_ERROR('Error: UserActions>>AddAddress', { error });
        dispatch({
          type: ActionConsts.Patient.AddAddressFail,
        });
      }
    },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetAllAddresses: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Patient.ResetAllAddressData,
    });
  },
};
