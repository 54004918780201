import { ActionConsts } from '@Definitions/ActionConsts';
import { IInventoryItemPurchase, InvoiceMode } from '@Services/Billing/billing-payload';

export interface IBilling {
  consultationInvoice: IConsultationInvoice;
  consultationInvoicePreview: IConsultationInvoice;
  prescriptionInvoicePreview: IConsultationInvoice;
  prescriptionInvoicePreviews: IConsultationInvoice[];
  prescriptionInvoice: IConsultationInvoice;
  inventoryItemPurchase: IInventoryItemPurchase;
}

const INITIAL_STATE: IBilling = {
  consultationInvoice: {},
  consultationInvoicePreview: {},
  prescriptionInvoicePreview: {},
  prescriptionInvoicePreviews: [],
  prescriptionInvoice: {},
  inventoryItemPurchase: {},
};

export interface IConsultationInvoice {
  id?: string;
  encounterId?: string;
  billingStrategy?: string;
  amount?: number;
  payableAmount?: number;
  dueAmount?: number;
  discountAmount?: number;
  listingAmount?: number;
  listingDiscount?: number;
  additionalDiscount?: number;
  status?: 'created' | 'cancelled' | 'paid';
  userId?: string;
  voucherCode?: string;
  mode?: InvoiceMode;
  items?: IInvoiceItem[];
  paidItems?: IInvoiceItem[];
  createdBy?: string;
  externalRequestId?: string;
  isValidCoupon?: boolean;
  isLoading?: boolean;
  error?: any; //temp
}

export interface IInvoiceItem {
  type: 'drug' | 'lab' | 'consultation';
  typeId: string;
  quantity: number;
  originalQuantity: number;
  purchasedQuantity?: number; //temp
  originalAmount: number;
  listingAmount: number;
  payableAmount: number;
  additionalDiscount: number;
  name: string;
  packSize: number;
  isOptional: boolean;
  isModified: boolean;
  selection?: IInvoiceItemSelection;
}

export interface IInvoiceItemSelection {
  quantity: number;
  isSelected: boolean;
}

/* eslint-disable-next-line complexity */
export const BillingReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Billing.GetConsultationInvoiceInit:
      return {
        ...state,
        consultationInvoice: {
          isLoading: true,
        },
      };
    case ActionConsts.Billing.GetConsultationInvoiceSuccess:
      return {
        ...state,
        consultationInvoice: {
          ...action.invoice,
          isLoading: false,
        },
      };
    case ActionConsts.Billing.GetConsultationInvoiceFail:
      return {
        ...state,
        consultationInvoice: {
          error: {
            error: action?.error,
            status: action?.status,
          },
          isLoading: false,
        },
      };
    case ActionConsts.Billing.ResetConsultationInvoice:
      return {
        ...state,
        consultationInvoice: INITIAL_STATE.consultationInvoice,
      };
    case ActionConsts.Billing.PreviewConsultationInvoiceInit:
      return {
        ...state,
        consultationInvoicePreview: {
          isLoading: true,
        },
      };
    case ActionConsts.Billing.PreviewConsultationInvoiceSuccess:
      return {
        ...state,
        consultationInvoicePreview: {
          ...action.invoicePreview,
          isLoading: false,
        },
      };
    case ActionConsts.Billing.PreviewConsultationInvoiceFail:
      return {
        ...state,
        consultationInvoicePreview: {
          isLoading: false,
        },
      };
    case ActionConsts.Billing.ResetConsultationInvoicePreview:
      return {
        ...state,
        consultationInvoicePreview: INITIAL_STATE.consultationInvoicePreview,
      };

    //new structure
    case ActionConsts.Billing.PreviewPrescriptionInvoicesInit:
      return {
        ...state,
        prescriptionInvoicePreviews: state.prescriptionInvoicePreviews?.filter(
          (invoice) => invoice.encounterId !== action.encounterId,
        ),
      };
    case ActionConsts.Billing.PreviewPrescriptionInvoicesFail:
      return {
        ...state,
      };
    case ActionConsts.Billing.PreviewPrescriptionInvoicesSuccess: {
      const updatedPreviews = [
        ...(state.prescriptionInvoicePreviews?.filter(
          (invoice) => invoice.encounterId !== action.invoice.encounterId,
        ) ?? []),
        action.invoice,
      ];
      return {
        ...state,
        prescriptionInvoicePreviews: updatedPreviews,
      };
    }
    case ActionConsts.Billing.ResetPrescriptionInvoicesPreview:
      return {
        ...state,
        prescriptionInvoicePreviews: INITIAL_STATE.prescriptionInvoicePreviews,
      };
    //legacy
    case ActionConsts.Billing.PreviewPrescriptionInvoiceInit:
      return {
        ...state,
        prescriptionInvoicePreview: {
          isLoading: true,
        },
      };
    case ActionConsts.Billing.PreviewPrescriptionInvoiceSuccess:
      return {
        ...state,
        prescriptionInvoicePreview: {
          ...action.invoicePreview,
          isLoading: false,
        },
      };
    case ActionConsts.Billing.PreviewPrescriptionInvoiceFail:
      return {
        ...state,
        prescriptionInvoicePreview: {
          isLoading: false,
        },
      };
    case ActionConsts.Billing.ResetPrescriptionInvoicePreview:
      return {
        ...state,
        prescriptionInvoicePreview: INITIAL_STATE.prescriptionInvoicePreview,
      };
    case ActionConsts.Billing.GetPrescriptionInvoiceInit:
      return {
        ...state,
        prescriptionInvoice: {
          isLoading: true,
        },
      };
    case ActionConsts.Billing.GetPrescriptionInvoiceSuccess:
      return {
        ...state,
        prescriptionInvoice: {
          ...action.invoice,
          isLoading: false,
        },
      };
    case ActionConsts.Billing.GetPrescriptionInvoiceFail:
      return {
        ...state,
        prescriptionInvoice: {
          isLoading: false,
        },
      };
    case ActionConsts.Billing.ResetPrescriptionInvoice:
      return {
        ...state,
        prescriptionInvoice: INITIAL_STATE.prescriptionInvoice,
      };
    case ActionConsts.Billing.CreateInventoryItemPurchaseInit:
      return {
        ...state,
        inventoryItemPurchase: {
          isLoading: true,
        },
      };
    case ActionConsts.Billing.CreateInventoryItemPurchaseSuccess:
      return {
        ...state,
        inventoryItemPurchase: {
          ...action.purchaseItem,
          isLoading: false,
        },
      };
    case ActionConsts.Billing.CreateInventoryItemPurchaseFail:
      return {
        ...state,
        inventoryItemPurchase: {
          isLoading: false,
        },
      };
    case ActionConsts.Billing.CreateInventoryItemPurchaseInit:
      return {
        ...state,
        inventoryItemPurchase: {
          isLoading: true,
        },
      };
    case ActionConsts.Billing.CreateInventoryItemPurchaseSuccess:
      return {
        ...state,
        inventoryItemPurchase: {
          ...action.purchaseItem,
          isLoading: false,
        },
      };
    case ActionConsts.Billing.CreateInventoryItemPurchaseFail:
      return {
        ...state,
        inventoryItemPurchase: {
          isLoading: false,
        },
      };
    case ActionConsts.Billing.HandleInvoiceItemSelection: {
      const mofiedInvoice = state.prescriptionInvoicePreviews.find(
        (invoice) => invoice.encounterId === action.encounterId,
      );
      if (!mofiedInvoice) return state;
      return {
        ...state,
        prescriptionInvoicePreviews: [
          ...state.prescriptionInvoicePreviews.filter((invoice) => invoice.encounterId !== action.encounterId),
          {
            ...mofiedInvoice,
            items: action.modifiedItems,
          },
        ],
      };
    }
    default:
      return state;
  }
};
