import { AxiosResponse } from 'axios';

import { Axios } from '../AxiosApiServerWrapper';
import {
  ICreateConsultationInvoicePayload,
  ICreatePrescriptionInvoicePayload,
  IInventoryItemPurchase,
} from './billing-payload';

export const BillingService = {
  PreviewConsultationInvoice: async (payload: ICreateConsultationInvoicePayload): Promise<AxiosResponse> => {
    const url = `billing/consultations/preview`;
    return Axios.Request('POST', url, payload);
  },
  GetConsultationInvoice: async (payload: ICreateConsultationInvoicePayload): Promise<AxiosResponse> => {
    const url = `billing/consultations`;
    return Axios.Request('POST', url, payload);
  },
  GetConsultationInvoiceWithoutHold: async (payload: ICreateConsultationInvoicePayload): Promise<AxiosResponse> => {
    const url = `billing/consultations/held/pay`;
    return Axios.Request('POST', url, payload);
  },
  PreviewPrescriptionInvoice: async (payload: ICreatePrescriptionInvoicePayload): Promise<AxiosResponse> => {
    if (payload?.items?.length === 0) return;
    const { ipxId, ...rest } = payload;
    const url = `billing/prescriptions/preview`;
    return Axios.Request('POST', url, rest, { ipxId });
  },
  PreviewPrescriptionInvoiceFuture: async (payload: ICreatePrescriptionInvoicePayload): Promise<AxiosResponse> => {
    const { ipxId, ...rest } = payload;
    const url = `billing/prescriptions/previewV2`;
    return Axios.Request('POST', url, rest, { ipxId });
  },
  GetInvoiceOverview: async (payload: ICreatePrescriptionInvoicePayload): Promise<AxiosResponse> => {
    const { ipxId, ...rest } = payload;
    const url = `billing/prescriptions/overview`;
    return Axios.Request('POST', url, rest, { ipxId });
  },
  GetPrescriptionInvoice: async (payload: ICreatePrescriptionInvoicePayload): Promise<AxiosResponse> => {
    const url = `billing/prescriptions`;
    return Axios.Request('POST', url, payload);
  },
  GetInventoryItemPurchase: async (payload: IInventoryItemPurchase): Promise<AxiosResponse> => {
    const url = `billing/direct-purchase`;
    return Axios.Request('POST', url, payload);
  },
  CreateInstantConsultationInvoice: async (payload: ICreateConsultationInvoicePayload): Promise<AxiosResponse> => {
    const url = `billing/consultations/instant`;
    return Axios.Request('POST', url, payload);
  },
};
