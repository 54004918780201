import { ActionConsts } from '@Definitions/ActionConsts';

export enum AppointmentStatus {
  CANCELLED = 'CANCELLED',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  RESCHEDULED = 'RESCHEDULED',
  RECONSULTED = 'RECONSULTED',
  IN_PROGRESS = 'IN_PROGRESS',
  MISSED = 'MISSED',
  PATIENT_JOINED = 'PATIENT_JOINED',
  PROVIDER_JOINED = 'PROVIDER_JOINED',
}

export interface IAppointmentData {
  id: string;
  startTime: string;
  status: string;
  message?: string;
  type?: string;
  consultationTypeId: string;
  consultationId?: string;
  patientMeetingLink?: string;
  hmsToken?: string;
}

export interface IClinicLocationsData {
  id: string;
  name: string;
  type: string;
  code: string;
  address?: string;
  city?: string;
  locality?: string;
  mapUrl?: string;
  imageLinks?: string[];
  workingHours?: string;
  latitude?: number;
  longitude?: number;
  distance?: number;
  totalRating?: number;
  rating?: number | string;
}

export interface IDefaultData {
  status?: number;
  message?: string;
}

export interface ISlot {
  providerId: string;
  blockId: string;
  startDate: string;
  endDate: string;
}

export interface ILabTimeFormat {
  start_time: string;
  end_time: string;
}

export interface ILabSlot {
  id: number;
  available_slot: number;
  format_24_hrs: ILabTimeFormat;
  format_12_hrs: ILabTimeFormat;
}

export type ISlotData = Record<string, Record<'Morning' | 'Afternoon' | 'Evening' | 'Instant', ISlot[]>>;

export enum TimePeriod {
  'Instant' = 'Instant',
  'Morning' = 'Morning',
  'Afternoon' = 'Afternoon',
  'Evening' = 'Evening',
}

export interface IAvailableLanguages {
  isLoading?: boolean;
  languages: string[];
}

export interface ISchedule {
  isLoading: boolean;
  appointmentData: IAppointmentData;
  slotsData: ISlotData;
  isFetchingSlots?: boolean;
  fetchedSlots?: boolean;
  consultationData: any;
  rescheduleData: IDefaultData;
  languages: string[];
  availableLanguages: IAvailableLanguages;
  providerId: string;
  patientId: string;
  ivrData: IDefaultData;
  gMeetData: IDefaultData;
  releaseConsultationSlot?: boolean;
  hideOnlineLocation?: boolean;
  hideOfflineLocation?: boolean;
}

const INITIAL_STATE: ISchedule = {
  isLoading: false,
  appointmentData: {
    id: '',
    startTime: '',
    status: '',
    message: '',
    consultationId: '',
    consultationTypeId: '',
  },
  slotsData: {},
  consultationData: {},
  rescheduleData: {},
  languages: [],
  availableLanguages: {
    languages: [],
  },
  providerId: '',
  patientId: '',
  ivrData: {},
  gMeetData: {},
  releaseConsultationSlot: undefined,
};

/* eslint-disable-next-line complexity */
export const ScheduleReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Schedule.GetAppointmentInit:
      return {
        ...state,
        appointmentData: INITIAL_STATE.appointmentData,
        providerId: INITIAL_STATE.providerId,
        patientId: INITIAL_STATE.patientId,
        isLoading: true,
      };
    case ActionConsts.Schedule.GetAppointmentSuccess:
      return {
        ...state,
        appointmentData: action.appointmentData,
        providerId: action.providerId,
        patientId: action.patientId,
        isLoading: false,
      };
    case ActionConsts.Schedule.GetAppointmentFail:
      return {
        ...state,
        providerId: INITIAL_STATE.providerId,
        patientId: INITIAL_STATE.patientId,
        appointmentData: {
          id: '',
          startTime: '',
          status: AppointmentStatus.CANCELLED,
          message: action.message,
        },
        slotsData: INITIAL_STATE.slotsData,
        isLoading: false,
      };
    case ActionConsts.Schedule.GetSlotsInit:
      return {
        ...state,
        slotsData: INITIAL_STATE.slotsData,
        isFetchingSlots: true,
        isLoading: true,
      };
    case ActionConsts.Schedule.GetSlotsSuccess:
      return {
        ...state,
        slotsData: action.slotsData,
        isFetchingSlots: false,
        fetchedSlots: true,
        isLoading: false,
      };
    case ActionConsts.Schedule.ResetSlotsData:
      return {
        ...state,
        slotsData: INITIAL_STATE.slotsData,
        isFetchingSlots: undefined,
        fetchedSlots: undefined,
        isLoading: false,
      };
    case ActionConsts.Schedule.GetSlotsFail:
      return {
        ...state,
        slotsData: INITIAL_STATE.slotsData,
        isFetchingSlots: false,
        fetchedSlots: true,
        isLoading: false,
      };
    case ActionConsts.Schedule.ResetAppointmentData:
      return {
        ...state,
        appointmentData: INITIAL_STATE.appointmentData,
        providerId: INITIAL_STATE.providerId,
        patientId: INITIAL_STATE.patientId,
        isLoading: false,
      };
    case ActionConsts.Schedule.GetLanguagesInit:
      return {
        ...state,
        languages: INITIAL_STATE.languages,
        isLoading: true,
      };
    case ActionConsts.Schedule.GetLanguagesSuccess:
      return {
        ...state,
        languages: action.languages,
        isLoading: false,
      };
    case ActionConsts.Schedule.GetLanguagesFail:
      return {
        ...state,
        languages: INITIAL_STATE.languages,
        isLoading: false,
      };
    case ActionConsts.Schedule.GetAvailableLanguagesInit:
      return {
        ...state,
        availableLanguages: {
          isLoading: true,
          languages: [],
        },
        isLoading: true,
      };
    case ActionConsts.Schedule.GetAvailableLanguagesSuccess:
      return {
        ...state,
        availableLanguages: {
          isLoading: false,
          languages: action.availableLanguages,
        },
        isLoading: false,
      };
    case ActionConsts.Schedule.GetAvailableLanguagesFail:
      return {
        ...state,
        availableLanguages: {
          isLoading: false,
          languages: [],
        },
        isLoading: false,
      };
    case ActionConsts.Schedule.UpdateConsultationStateInit:
      return {
        ...state,
        consultationData: INITIAL_STATE.consultationData,
        isLoading: true,
      };
    case ActionConsts.Schedule.UpdateConsultationStateSuccess:
      return {
        ...state,
        consultationData: action.consultationData,
        isLoading: false,
      };
    case ActionConsts.Schedule.ResetConsultationData:
    case ActionConsts.Schedule.UpdateConsultationStateFail:
      return {
        ...state,
        consultationData: INITIAL_STATE.consultationData,
        isLoading: false,
      };
    case ActionConsts.Schedule.RescheduleConsultationInit:
      return {
        ...state,
        rescheduleData: INITIAL_STATE.rescheduleData,
        isLoading: true,
      };
    case ActionConsts.Schedule.RescheduleConsultationSuccess:
    case ActionConsts.Schedule.RescheduleConsultationFail:
      return {
        ...state,
        rescheduleData: action.rescheduleData,
        isLoading: false,
      };
    case ActionConsts.Schedule.ReleaseConsultationSlotInit:
      return {
        ...state,
        releaseConsultationSlot: INITIAL_STATE.releaseConsultationSlot,
        isLoading: true,
      };
    case ActionConsts.Schedule.ReleaseConsultationSlotSuccess:
    case ActionConsts.Schedule.ReleaseConsultationSlotFail:
      return {
        ...state,
        releaseConsultationSlot: action.releaseConsultationSlot,
        isLoading: false,
      };
    case ActionConsts.Schedule.ResetRescheduleData:
      return {
        ...state,
        rescheduleData: INITIAL_STATE.rescheduleData,
        isLoading: false,
      };
    case ActionConsts.Schedule.ResetReleaseConsultationSlot:
      return {
        ...state,
        releaseConsultationSlot: INITIAL_STATE.releaseConsultationSlot,
        isLoading: false,
      };
    case ActionConsts.Schedule.AppointmentIVRInit:
      return {
        ...state,
        ivrData: INITIAL_STATE.ivrData,
        isLoading: true,
      };
    case ActionConsts.Schedule.AppointmentIVRSuccess:
    case ActionConsts.Schedule.AppointmentIVRFail:
      return {
        ...state,
        ivrData: action.ivrData,
        isLoading: false,
      };
    case ActionConsts.Schedule.ResetIVRData:
      return {
        ...state,
        ivrData: INITIAL_STATE.ivrData,
        isLoading: false,
      };
    case ActionConsts.Schedule.AppointmentGMeetInit:
      return {
        ...state,
        gMeetData: INITIAL_STATE.gMeetData,
        isLoading: true,
      };
    case ActionConsts.Schedule.AppointmentGMeetSuccess:
    case ActionConsts.Schedule.AppointmentGMeetFail:
      return {
        ...state,
        gMeetData: action.gMeetData,
        isLoading: false,
      };
    case ActionConsts.Schedule.ResetGMeetData:
      return {
        ...state,
        gMeetData: INITIAL_STATE.gMeetData,
        isLoading: false,
      };
    case ActionConsts.Schedule.SetHideOfflineLocation:
      return {
        ...state,
        hideOfflineLocation: true,
      };
    case ActionConsts.Schedule.SetHideOnlineLocation:
      return {
        ...state,
        hideOnlineLocation: true,
      };
    default:
      return state;
  }
};
