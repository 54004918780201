import { ActionConsts } from '@Definitions/index';

export const INITIAL_STATE = {
  patientFulfillments: [],
  patientAllFulfillments: [],
  drugFulfillments: [],
  labFulfillments: [],
  invoiceBill: {},
  isLoading: false,
};

export const PatientFulfillmentReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.PatientFulfillment.GetPatientFulFillmentsInit:
      return {
        ...state,
        isLoading: true,
      };
    case ActionConsts.PatientFulfillment.GetPatientFulFillmentsSuccess:
      return {
        ...state,
        isLoading: false,
        patientFulfillments: action.patientFulfillments,
      };
    case ActionConsts.PatientFulfillment.GetPatientFulFillmentsFail:
      return {
        ...state,
        isLoading: false,
      };
    case ActionConsts.PatientFulfillment.GetPatientAllFulFillmentsInit:
      return {
        ...state,
        isLoading: true,
      };
    case ActionConsts.PatientFulfillment.GetPatientAllFulFillmentsSuccess:
      return {
        ...state,
        isLoading: false,
        patientAllFulfillments: action.patientAllFulfillments,
      };
    case ActionConsts.PatientFulfillment.GetPatientAllFulFillmentsFail:
      return {
        ...state,
        isLoading: false,
      };
    case ActionConsts.PatientFulfillment.PostFulfillmentsBillInit:
      return {
        ...state,
        isLoading: true,
      };
    case ActionConsts.PatientFulfillment.PostFulfillmentsBillSuccess:
      return {
        ...state,
        isLoading: false,
        invoiceBill: action.invoiceBill,
      };
    case ActionConsts.PatientFulfillment.PostFulfillmentsBillFail:
      return {
        ...state,
        isLoading: false,
      };
    case ActionConsts.PatientFulfillment.GetPatientDrugFulfillmentsInit:
      return {
        ...state,
        drugFulfillments: [],
        isLoading: true,
      };
    case ActionConsts.PatientFulfillment.GetPatientDrugFulfillmentsSuccess:
      return {
        ...state,
        isLoading: false,
        drugFulfillments: action.drugFulfillments,
      };
    case ActionConsts.PatientFulfillment.GetPatientDrugFulfillmentsFail:
      return {
        ...state,
        isLoading: false,
        drugFulfillments: [],
      };
    case ActionConsts.PatientFulfillment.GetPatientLabFulfillmentsInit:
      return {
        ...state,
        isLoading: true,
        labFulfillments: [],
      };
    case ActionConsts.PatientFulfillment.GetPatientLabFulfillmentsSuccess:
      return {
        ...state,
        isLoading: false,
        labFulfillments: action.labFulfillments,
      };
    case ActionConsts.PatientFulfillment.GetPatientLabFulfillmentsFail:
      return {
        ...state,
        isLoading: false,
        labFulfillments: [],
      };
    default:
      return state;
  }
};
